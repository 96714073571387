import * as React from 'react';
import {makeStyles} from '@material-ui/styles';
import styles from "common/styles";
import {Link as ReactLink} from "react-router-dom";

const useStyles = makeStyles({
    link: {
        ...styles.link,
    }
});

interface Props {
    url: string
    name: string
}

export const Link: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const {url, name} = props;

    return (
        <ReactLink to={url} className={classes.link}>{name}</ReactLink>
    );
};

