import {combineReducers} from 'redux';
import {UsersAppState} from "users/store/states";
import {UsersReducer} from "users/store/reducers";
import {CourtsAppState} from "courts/store/states";
import {CommonAppState} from "common/store/states";
import {CommonReducer} from "common/store/reducers";
import {CourtsAppReducer} from "./courts/store/reducers";
import {PostsAppReducer} from "./posts/store/reducers";
import {PostsAppState} from "./posts/store/states";


export interface StateInterface {
    users: UsersAppState;
    courts: CourtsAppState;
    posts: PostsAppState;
    common: CommonAppState;
}

export const state = combineReducers<StateInterface>({
    users: UsersReducer,
    courts: CourtsAppReducer,
    posts: PostsAppReducer,
    common: CommonReducer,
});