import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import LinearProgress from '@material-ui/core/LinearProgress';
import {areasActions} from "../store/actions";
import {StateInterface} from "reducers";
import {Area, City} from "../models";
import {Layout} from "./Layout";
import Select from '@material-ui/core/Select';
import {
    FilteringState,
    IntegratedFiltering,
    SortingState, IntegratedSorting, DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
    Grid,
    TableFilterRow,
    TableHeaderRow,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {useState} from "react";
import {Link} from "./Link";
import {MenuItem, TableCell, Theme, withStyles} from "@material-ui/core";
import {ActiveIndicator} from "./ActiveIndicator";
import {makeStyles} from "@material-ui/styles";

const styles = (theme: Theme) => ({
    cell: {
        width: '100%',
        padding: theme.spacing(1),
    },
    input: {
        fontSize: '14px',
        width: '100%',
    },
});

const StatusFilterCellBase = (props: any) => {
    const { filter, onFilter, classes } = props;
    return <TableCell className={classes.cell}>
        <Select
            className={classes.input}
            value={filter ? filter.value?1:0 : ''}
            onChange={(e: any) => onFilter(e.target.value !== "" ? { value: e.target.value === 1} : null)}
        >
            <MenuItem value={""}>Любой</MenuItem>
            <MenuItem value={1}>Доступен</MenuItem>
            <MenuItem value={0}>Не доступен</MenuItem>
        </Select>
    </TableCell>;
};

const StatusFilterCell = withStyles(styles, { name: 'UnitsFilterCell' })(StatusFilterCellBase);

const FilterCell = (props: any) => {
    const { column } = props;
    if (column.name === 'status') {
        return <StatusFilterCell {...props} />;
    }
    return <TableFilterRow.Cell {...props} />;
};

const CityFormatter = (props: any) => {
    return <Link url={`/areas/${props.row.areaId}/cities/${props.row.cityId}/courts`} name={props.value}/>;
};

const CityTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={CityFormatter}
        {...props}
    />
);

const StatusFormatter = (props: any) => {
    return <ActiveIndicator active={props.value}/>;
};

const StatusTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={StatusFormatter}
        {...props}
    />
);

const useStyles = makeStyles({
    content: {
        marginTop: "70px"
    }
});


export const AreasPage: React.FC = () => {
    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect( () => {
        dispatch(areasActions.fetchAll());
    },[dispatch]);

    const areas = useSelector((state: StateInterface) => state.common.areas.data);
    const isLoading = useSelector((state: StateInterface) => state.common.areas.isLoading);

    interface Row {
        areaId: number,
        areaName: string,
        cityId: number,
        cityName: string,
        cityPopulation: number,
        cityStatus: boolean
    }

    let rows: Row[] = [];
    areas.forEach((area: Area) => {
        area.cities.forEach((city: City) => {
            rows.push({
                areaId: area.id,
                areaName: area.name,
                cityId: city.id,
                cityName: city.name,
                cityPopulation: city.population,
                cityStatus: city.status
            } as Row)
        });
    });

    const [columns] = useState([
        { name: 'region', title: 'Регион', getCellValue: (row: Row) => row.areaName },
        { name: 'city', title: 'Город', getCellValue: (row: Row) => row.cityName },
        { name: 'population', title: 'Население', getCellValue: (row: Row) => row.cityPopulation },
        { name: 'status', title: 'Статус', getCellValue: (row: Row) => row.cityStatus },
    ]);

    const [tableColumnExtensions] = useState([
        { columnName: 'population', align: 'right' } as any,
    ]);

    const [cityColumns] = useState(['city']);
    const [statusColumns] = useState(['status']);

    return (
        <Layout pageName={'Регионы'}>
            <div className={classes.content}>
            {isLoading ?
                <LinearProgress/>
                :
                <Grid
                    rows={rows}
                    columns={columns}
                >
                    <FilteringState columnExtensions={[
                        { columnName: 'population', filteringEnabled: false }
                    ]}/>
                    <SortingState
                        defaultSorting={[
                            { columnName: 'region', direction: 'asc' },
                            { columnName: 'city', direction: 'asc' },
                        ]}
                    />
                    <IntegratedFiltering />
                    <IntegratedSorting />
                    <CityTypeProvider for={cityColumns} />
                    <StatusTypeProvider for={statusColumns} />
                    <VirtualTable
                        columnExtensions={tableColumnExtensions}
                        cellComponent={(props: any) => {return <VirtualTable.Cell {...props} />;}}
                    />
                    <TableFilterRow showFilterSelector={true} cellComponent={FilterCell}/>
                    <TableHeaderRow showSortingControls />
                </Grid>
            }
            </div>
        </Layout>
    );
};

