import ajax from '@codexteam/ajax';
require('./index.css').toString();

export default class IconAndText {
    /**
     * Get Tool toolbox settings
     * icon - Tool icon's SVG
     * title - title to show in toolbox
     *
     * @return {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15pt" height="15pt" viewBox="0 0 15 15" version="1.1">
                <g id="surface1">
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 10.671875 6.792969 L 15.359375 6.792969 L 15.359375 7.730469 L 10.671875 7.730469 Z M 10.671875 6.792969 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 0 10.3125 L 15 10.3125 L 15 11.25 L 0 11.25 Z M 0 10.3125 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 0 13.125 L 11.25 13.125 L 11.25 14.0625 L 0 14.0625 Z M 0 13.125 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 10.671875 2.910156 L 15.359375 2.910156 L 15.359375 3.847656 L 10.671875 3.847656 Z M 10.671875 2.910156 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 0.46875 8.4375 L 8.90625 8.4375 C 9.164062 8.4375 9.375 8.226562 9.375 7.96875 L 9.375 1.40625 C 9.375 1.148438 9.164062 0.9375 8.90625 0.9375 L 0.46875 0.9375 C 0.210938 0.9375 0 1.148438 0 1.40625 L 0 7.96875 C 0 8.226562 0.210938 8.4375 0.46875 8.4375 Z M 0.9375 1.875 L 8.4375 1.875 L 8.4375 7.5 L 5.625 4.6875 L 2.8125 7.5 L 0.9375 7.5 Z M 0.9375 1.875 "/>
            <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 3.476562 3.085938 C 3.839844 3.453125 3.839844 4.046875 3.476562 4.414062 C 3.109375 4.777344 2.515625 4.777344 2.148438 4.414062 C 1.785156 4.046875 1.785156 3.453125 2.148438 3.085938 C 2.515625 2.722656 3.109375 2.722656 3.476562 3.085938 "/>
            </g>
    </svg>`,
            title: 'Иконка и текст'
        };
    }

    /**
     * Empty Quote is not empty Block
     * @public
     * @returns {boolean}
     */
    static get contentless() {
        return true;
    }

    /**
     * Allow to press Enter inside the Quote
     * @public
     * @returns {boolean}
     */
    static get enableLineBreaks() {
        return true;
    }

    /**
     * Default quote alignment
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_ALIGNMENT() {
        return IconAndText.ALIGNMENTS.left;
    }

    /**
     * Allow Quote to be converted to/from other blocks
     */
    static get conversionConfig(){
        return {
            /**
             * To create Quote data from string, simple fill 'text' property
             */
            import: 'text',
            /**
             * To create string from Quote data, concatenate text and caption
             * @param {QuoteData} quoteData
             * @return {string}
             */
            export: function (quoteData) {
                return quoteData.headline ? `${quoteData.text} — ${quoteData.headline}` : quoteData.text;
            }
        };
    }

    /**
     * Tool`s styles
     *
     * @returns {{baseClass: string, wrapper: string, quote: string, input: string, caption: string, settingsButton: string, settingsButtonActive: string}}
     */
    get CSS() {
        return {
            baseClass: this.api.styles.block,
            button: this.api.styles.button,
            widget: 'widget',
            uploadBtn: 'uploadBtn',
            previewIcon: 'previewIcon',
            wrapper: 'cdx-quote',
            text: 'cdx-quote__text',
            input: this.api.styles.input,
            headline: 'cdx-headline',
            customIconUploader: 'customIconUploader',
            settingsWrapper: 'cdx-quote-settings',
            settingsButton: this.api.styles.settingsButton,
            settingsButtonActive: this.api.styles.settingsButtonActive,
            typeSelector: 'cdx-quote__variant-selector',
        };
    }

    /**
     * Render plugin`s main Element and fill it with saved data
     *
     * @param {{data: QuoteData, config: QuoteConfig, api: object}}
     *   data — previously saved data
     *   config - user config for Tool
     *   api - Editor.js API
     */
    constructor({data, config, api}) {
        this.api = api;
        this.config = config;

        this.data = {
            headline: data.headline || '',
            text: data.text || '',
            type: data.type?data.type:'question',
            icon: data.icon,
        };

        const typeSelector = this._make('select', [this.CSS.input, this.CSS.typeSelector]);
        const questionOption = document.createElement("option");
        questionOption.value = 'question';
        questionOption.text = "Вопрос";
        typeSelector.append(questionOption);

        const warningOption = document.createElement("option");
        warningOption.value = 'warning';
        warningOption.text = "Внимание";
        typeSelector.append(warningOption);

        const linksOption = document.createElement("option");
        linksOption.value = 'links';
        linksOption.text = "Ссылки";
        typeSelector.append(linksOption);

        const otherOption = document.createElement("option");
        otherOption.value = 'other';
        otherOption.text = "Другое";
        typeSelector.append(otherOption);

        typeSelector.value = data.type;

        const headline = this._make('div', [this.CSS.input, this.CSS.headline],{
            contentEditable: true,
            innerHTML: this.data.headline
        });

        headline.dataset.placeholder = 'Заголовок';

        const text = this._make('div', [this.CSS.input, this.CSS.text],{
            contentEditable: true,
            innerHTML: this.data.text
        });

        text.dataset.placeholder = 'Текст';

        this.nodes = {
            button: null,
            wrapper: this._make('div', [this.CSS.baseClass, this.CSS.widget]),
            typeSelector: typeSelector,
            customIconUploader: this._make('div', [this.CSS.customIconUploader]),
            headline: headline,
            text: text,
        };

        this.nodes.text.dataset.placeholder = 'Текст';

        if (data.type === 'other') {
            this.nodes.customIconUploader.style.display = 'block';
        } else {
            this.nodes.customIconUploader.style.display = 'none';
        }

        const me = this;
        this.nodes.typeSelector.onchange = () => {
            me.data =  Object.assign(me.data, {
                type: typeSelector.value,
            });

            if (typeSelector.value === 'other') {
                me.nodes.customIconUploader.style.display = 'block';
            } else {
                me.nodes.customIconUploader.style.display = 'none';
            }
        };

        this.nodes.wrapper.appendChild(this.nodes.typeSelector);
        this.nodes.wrapper.appendChild(this.nodes.customIconUploader);
        this.nodes.wrapper.appendChild(this.nodes.headline);
        this.nodes.wrapper.appendChild(this.nodes.text);

        this.enableFileUpload = this.enableFileUpload.bind(this);
        this.renderIconUploader = this.renderIconUploader.bind(this);

        this.renderIconUploader();
    }

    renderIconUploader() {
        this.nodes.customIconUploader.innerHTML = '';

        if (this.data.icon) {
            const icon = this._make('img', [this.CSS.previewIcon], {
                src: process.env.REACT_APP_DOMAIN + this.data.icon
            });
            this.nodes.customIconUploader.appendChild(icon);
        }
        const uploadBtn = this._make('div', [this.CSS.button, this.CSS.uploadBtn], {
            innerHTML: 'Выбрать иконку'
        });
        uploadBtn.addEventListener('click', this.enableFileUpload);
        this.nodes.customIconUploader.appendChild(uploadBtn);
    }

    enableFileUpload() {
        const upload = async () => {
            const upl  = ajax.selectFiles({ accept: 'image/jpeg' }).then((files) => {
                const customUpload = this.config.uploader.uploadByFile(files[0]);

                if (!isPromise(customUpload)) {
                    console.warn('Custom uploader method uploadByFile should return a Promise');
                }
                return customUpload;
            }).catch((error) => {
                this.api.notifier.show({
                    message: "Что-то пошло не так",
                    style: 'error'
                });
            });

            this.data.icon = (await upl).file.source;
            this.renderIconUploader();
        };

        upload();

    }

    /**
     * Create Quote Tool container with inputs
     *
     * @returns {Element}
     */
    render(data) {
        return this.nodes.wrapper;
    }

    /**
     * Extract Quote data from Quote Tool element
     *
     * @param {HTMLDivElement} quoteElement - element to save
     * @returns {QuoteData}
     */
    save(quoteElement) {
        const headline = quoteElement.querySelector(`.${this.CSS.headline}`);
        const text = quoteElement.querySelector(`.${this.CSS.text}`);

        return Object.assign(this.data, {
            type: this.data.type,
            headline: headline.innerHTML,
            text: text.innerHTML,
            icon: this.data.icon
        });
    }

    /**
     * Sanitizer rules
     */
    static get sanitize() {
        return {
            text: {
                br: true,
            },
            headline: {
                br: true,
            },
        };
    }

    /**
     * Toggle quote`s alignment
     *
     * @param {string} tune - alignment
     * @private
     */
    _toggleTune(tune) {
        this.data.alignment = tune;
    }

    /**
     * Helper for making Elements with attributes
     *
     * @param  {string} tagName           - new Element tag name
     * @param  {array|string} classNames  - list or name of CSS classname(s)
     * @param  {Object} attributes        - any attributes
     * @return {Element}
     */
    _make(tagName, classNames = null, attributes = {}) {
        let el = document.createElement(tagName);

        if ( Array.isArray(classNames) ) {
            el.classList.add(...classNames);
        } else if( classNames ) {
            el.classList.add(classNames);
        }

        for (let attrName in attributes) {
            el[attrName] = attributes[attrName];
        }

        return el;
    }
}

function isPromise(object) {
    return Promise.resolve(object) === object;
}
