import {types} from './types';
import {Post} from "posts/models";
import {Error} from "common/models";
import {FORM_ERROR} from "final-form";
import * as HttpStatus from 'http-status-codes';
import {ApiClient} from "../../../common/api-client";
import {store} from "../../../store";

const create = (post: Post) => (dispatch: any) => {
    let data = Object.assign({}, post) as any;

    return ApiClient.post('/posts/', data)
        .then(function (response: any) {
            return response.data
        })
        .catch(function (error: any) {
            console.dir(error);
            let errors: Error = {};
            if (error.response.status === HttpStatus.BAD_REQUEST) {
                for (let [key, value] of Object.entries(error.response.data)) {
                    errors[key === 'non_field_errors' ? FORM_ERROR : key] = (value as string[])[0];
                }
            } else {
                errors[FORM_ERROR] = "Что-то пошло не так... Код: " + error.response.status;
            }

            return Promise.reject(errors);
        });
};

const update = (post: Post) => (dispatch: any) => {
    let data = Object.assign({}, post) as any;

    return ApiClient.put(`/posts/${post.id}/`, data)
        .then(function (response: any) {
            return response.data
        })
        .catch(function (error: any) {
            console.dir(error);
            let errors: Error = {};
            if (error.response.status === HttpStatus.BAD_REQUEST) {
                for (let [key, value] of Object.entries(error.response.data)) {
                    errors[key === 'non_field_errors' ? FORM_ERROR : key] = (value as string[])[0];
                }
            } else {
                errors[FORM_ERROR] = "Что-то пошло не так... Код: " + error.response.status;
            }

            return Promise.reject(errors);
        });
};

const savePreview = (post: Post) => (dispatch: any) => {
    let data = Object.assign({}, post) as any;

    return ApiClient.post(`/posts/save_preview/`, data)
        .then(function (response: any) {
            return response.data
        })
        .catch(function (error: any) {
            console.dir(error);
            let errors: Error = {};
            if (error.response.status === HttpStatus.BAD_REQUEST) {
                for (let [key, value] of Object.entries(error.response.data)) {
                    errors[key === 'non_field_errors' ? FORM_ERROR : key] = (value as string[])[0];
                }
            } else {
                errors[FORM_ERROR] = "Что-то пошло не так... Код: " + error.response.status;
            }

            return Promise.reject(errors);
        });
};

const fetch = (id: number) => (dispatch: any) => {
    dispatch(postLoad());
    return ApiClient.get(`/posts/${id}/`)
        .then(function (response: any) {
            dispatch(postLoadingSuccess(response.data));
            return response.data;
        })
        .catch(function (error: any) {
            console.dir(error);
            return Promise.reject(error);
        });
};

const postLoad = () => ({
    type: types.POST_LOADING_PROCESS
});

const postLoadingSuccess = (post: Post) => ({
    type: types.POST_LOADING_SUCCESS,
    payload: {
        data: post,
    }
});

const deletePost = (id: number) => (dispatch: any) => {
    const posts = store.getState().posts.posts.data;
    dispatch(postsLoadingSuccess(posts.filter( (post: Post) => post.id !== id)));
    return ApiClient.delete(`/posts/${id}/`);
};

const fetchAll = () => (dispatch: any) => {
    dispatch(postsLoadingProcess());

    return ApiClient.get('/posts/')
        .then((response: any) => {
            dispatch(postsLoadingSuccess(response.data.data as Post[]));
        })
        .catch(function (error: any) {
            console.dir(error);
        });
};

const postsLoadingProcess = () => ({
    type: types.POSTS_LOADING_PROCESS,
    payload: {
        //filters: filters,
    }
});

const postsLoadingSuccess = (posts: Post[]) => ({
    type: types.POSTS_LOADING_SUCCESS,
    payload: {
        data: posts,
    }
});

export const postsActions = {
    create,
    update,
    deletePost,
    fetch,
    fetchAll,
    savePreview,
};