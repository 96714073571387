import {types} from "posts/store/actions/types";
import {PostsState} from "../states";

const initialState = (): PostsState => ({
    data: [],
    isLoading: false,
    filters: null,
});

export const PostsReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.POSTS_LOADING_PROCESS:
            return Object.assign({}, state, {
                isLoading: true,
                filters: action.payload.filters,
            });
        case types.POSTS_LOADING_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                data: action.payload.data,
            });
    }
    return state;
};