import {types} from './types';
import {Area} from "common/models";
import {ApiClient} from "../../api-client";


const fetchAll = () => (dispatch: any) => {
    dispatch(areasLoadingProcess());

    return ApiClient.get('/core/areas/')
        .then((response: any) => {
            dispatch(areasLoadingSuccess(response.data.data as Area[]));
            return response.data;
        });
};

const areasLoadingProcess = () => ({
    type: types.AREAS_LOADING_PROCESS
});

const areasLoadingSuccess = (areas: Area[]) => ({
    type: types.AREAS_LOADING_SUCCESS,
    payload: {
        data: areas,
    }
});

export const areasActions = {
    fetchAll,
};