import * as React from 'react';
import {Field, Form} from "react-final-form";
import commonStyles from "common/styles";
import {makeStyles} from '@material-ui/styles';
import {Button, createStyles, Theme} from "@material-ui/core";
import 'react-sortable-tree/style.css';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import {FieldError} from "common/components";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {ContactSite, ContactTypes} from "../models";
import {validators} from "common/validators";

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    }
}));

interface AddContactPopupProps {
    site?: string
    onSave: (values: ContactSite[]) => any,
    handleClose: () => any
}

export const NewContactSite: React.FC<AddContactPopupProps> = (props: AddContactPopupProps) => {
    const classes = useStyles();

    const initial: ContactSite = {
        type: ContactTypes.Site,
        title: 'Официальный сайт',
        site: props.site?props.site:'',
    };

    const onSubmit = (contact: ContactSite) => {
        props.onSave([contact]);
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initial}
            render={({ handleSubmit, hasValidationErrors }) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <Dialog
                        open={true}
                        onClose={props.handleClose}
                    >
                        <form onSubmit={handleSubmit}>
                            <DialogTitle>{"Сайт"}</DialogTitle>
                            <DialogContent>
                                <Field
                                    name="title"
                                    component="input"
                                    type="text"
                                    validate={validators.required}
                                    required
                                >
                                    {({ input, meta, ...rest }) => (
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Заголовок"
                                                {...input} {...rest}
                                                error={!!meta.error || !!meta.submitError}
                                            />
                                            {(meta.error || meta.submitError) && meta.touched && (
                                                <FieldError text={meta.error || meta.submitError}/>
                                            )}
                                        </FormControl>
                                    )}
                                </Field>
                                <Field
                                    name="site"
                                    component="input"
                                    validate={validators.composeValidators(validators.required)}
                                    type="text"
                                    required
                                >
                                    {({ input, meta, ...rest }) => (
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                autoFocus
                                                label="Сайт"
                                                {...input} {...rest}
                                                error={!!meta.error || !!meta.submitError}
                                            />
                                            {(meta.error || meta.submitError) && meta.touched && (
                                                <FieldError text={meta.error || meta.submitError}/>
                                            )}
                                        </FormControl>
                                    )}
                                </Field>
                                <Field
                                    name="text"
                                    component="input"
                                    type="text"
                                >
                                    {({ input, meta, ...rest }) => (
                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                label="Текст"
                                                {...input} {...rest}
                                                error={!!meta.error || !!meta.submitError}
                                            />
                                            {(meta.error || meta.submitError) && meta.touched && (
                                                <FieldError text={meta.error || meta.submitError}/>
                                            )}
                                        </FormControl>
                                    )}
                                </Field>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    disabled={hasValidationErrors}
                                    variant="contained"
                                    color="primary"
                                    onClick={event => {
                                        submit(event);
                                    }}
                                >
                                    Добавить
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={event => {
                                        props.handleClose();
                                    }}
                                >
                                    Отмена
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                )
            }}/>
    );
};