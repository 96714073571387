import React, {Component} from 'react';
import PropTypes from 'prop-types';

import EditorJS from '@editorjs/editorjs';
import commonTools from './CommonTools';

class Editor extends Component {
    static defaultProps = {
        holder: 'editorjs',
        customTools: {},
        excludeDefaultTools: [],
        onChange: () => {
        },
        onBlur: () => {
        },
        onReady: () => {
        },
        data: {},
        //autofocus: true,
    };

    static propTypes = {
        holder: PropTypes.string,
        customTools: PropTypes.object,
        excludeDefaultTools: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onReady: PropTypes.func,
        data: PropTypes.object,
        autofocus: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this._tools = this._initTools(props.tools, props.excludeTools);

        this._onChange = props.onChange;
        this._onBlur = props.onBlur;
        this._onReady = props.onReady;

        this._el = React.createRef();
    }

    componentDidMount() {
        this._initEditor();
    }

    componentWillUnmount() {
        this._destroyEditor();
    }

    _initEditor = () => {
        const {holder, autofocus, data} = this.props;

        this.editor = new EditorJS({
            holder,
            autofocus,
            data,
            tools: this._tools,
            logLevel: 'ERROR',
            onChange: this._handleChange,
            onBlur: this._handleBlur,
            onReady: this._handleReady,
        });
    };

    _destroyEditor = () => {
        if (!this.editor || !(this.editor.destroy instanceof Function)) return;

        try {
            this.editor.destroy();
            this.editor = null;
        } catch (e) {
        }
    };

    _initTools = () => {
        const {customTools, excludeDefaultTools} = this.props;
        const toolsList = {...commonTools, ...customTools};

        if (excludeDefaultTools.length !== 0) {
            return Object.keys(toolsList)
                .filter(tool => !excludeDefaultTools.includes(tool))
                .reduce((acc, curr) => ({...acc, [curr]: toolsList[curr]}), {});
        }

        return toolsList;
    };

    _handleChange = async () => {
        const data = await this.editor.save();
        this._onChange(data);
    };

    _handleBlur = async () => {
        const data = await this.editor.save();
        this._onBlur(data);
    };

    _handleReady = () => {
        this._onReady();
    };

    render() {
        const {holder} = this.props;
        return React.createElement('div', {
            id: holder,
            ref: this._el,
        });
    }
}

export default Editor;