import * as React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    label: {
        marginLeft: '0.5rem',
    }

});

interface Props {
    icon: any
    label?: string
    url: string
}

export const ActionButton: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const {icon, label, url} = props;

    const isExternal = (url: string) => url.startsWith("http");

    return (
        <>
            {isExternal(url) ?
                <a
                    rel="noopener noreferrer"
                    href={url}
                    target={"_blank"}
                    style={{textDecoration: "none"}}>
                    <Button
                        size={"small"}
                        color={"primary"}>
                        {icon}
                        {label && <span className={classes.label}>{label}</span>}
                    </Button>
                </a> :
                <Link to={url} style={{textDecoration: "none"}}>
                    <Button
                        size={"small"}
                        color={"primary"}>
                        {icon}
                        {label && <span className={classes.label}>{label}</span>}
                    </Button>
                </Link>
            }
        </>
    );
};

