import * as React from 'react';
import {createStyles, TextField as MuiTextField, Theme} from "@material-ui/core";
import {makeStyles} from '@material-ui/styles';
import commonStyles from "common/styles";
import FormControl from '@material-ui/core/FormControl';
import {FieldError} from "./FieldError";
import {Field} from "react-final-form";
import {validators} from "../validators";

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    editor: {
        marginTop: '0.3rem'
    }
}));

interface Props {
    name: string
    label?: string
    placeholder?: string
    required?: boolean
    type?: string
    multiline?: boolean
    disabled?: boolean
}

export const TextField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name} = props;

    const required = !!props.required;

    const type = props.type ? props.type : 'text';
    const label = props.label ? props.label : '';
    const multiline = !!props.multiline;
    const disabled = !!props.disabled;
    const placeholder = props.placeholder ? props.placeholder : '';

    let validatorsList: any = [];
    if (required) {
        validatorsList.push(validators.required);
    }

    return (
        <Field
            name={name}
            component="input"
            type={type}
            validate={validators.composeValidators(...validatorsList)}
            required={required}
        >
            {({input, meta, ...rest}) => {
                const error = meta.error || meta.submitError;
                return <FormControl className={classes.formControl}>
                    <MuiTextField
                        {...input} {...rest}
                        label={label}
                        placeholder={placeholder}
                        multiline={multiline}
                        disabled={disabled}
                        error={!!meta.error || !!meta.submitError}
                    />
                    {meta.touched && error &&
                    <FieldError text={error}/>
                    }
                </FormControl>
            }}
        </Field>
    );
};

