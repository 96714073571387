import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {match} from "react-router-dom";
import {sectorsActions} from "../store/actions";
import {StateInterface} from "reducers";
import 'react-sortable-tree/style.css';
import * as H from "history";
import {Layout, Loadable} from "common/components";
import {Sector} from "../models";
import {SectorForm} from "./SectorForm";

interface WithSectorId {
    area_id: number
    city_id: number
    court_id: number
    sector_id: number
}

interface RouteComponentProps<WithSectorId> {
    match: match<WithSectorId>;
    location: H.Location;
    history: H.History;
    staticContext?: any;
}

export const SectorEditPage = ({match}: RouteComponentProps<WithSectorId>) => {
    const dispatch = useDispatch();

    useEffect( () => {
        const {sector_id} = match.params;
        dispatch(sectorsActions.fetch(sector_id));
    },[dispatch, match.params]);

    //const [isUpdated, setIsUpdated] = useState(false);

    const sector = useSelector((state: StateInterface) => state.courts.sector.data);
    //const courtsFilters = useSelector((state: StateInterface) => state.courts.courts.filters);
    const isLoading = useSelector((state: StateInterface) => state.courts.sector.isLoading);

    useEffect( () => {
        window.scrollTo(0, 0);
    }, [sector]);


    const onsubmit = async (courtSector: Sector) => {
        //setIsUpdated(true);
        try {
            await dispatch(sectorsActions.update(courtSector));
            // @ts-ignore
            //await dispatch(courtsActions.fetchAll(courtsFilters));
            //setIsUpdated(true);
        } catch (e) {
            console.dir(e);
            return e;
        }
    };
    // {isUpdated && <Redirect to={`/areas/${match.params.area_id}/cities/${match.params.city_id}/courts`}/>}
    return (
        <Layout pageName={sector?sector.name:''}>
            <Loadable isLoading={isLoading}>
                {sector &&
                    <SectorForm
                        initial={sector}
                        onSubmit={onsubmit}
                    />
                }
            </Loadable>
        </Layout>
    );
};

