import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {match} from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
import {courtsActions} from "../store/actions";
import {StateInterface} from "reducers";
import {CourtsFilters} from "../models";
import 'react-sortable-tree/style.css';
import * as H from "history";
import EditIcon from '@material-ui/icons/Edit';
import {CustomTreeData, TreeDataState, FilteringState, DataTypeProvider} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow, TableTreeColumn,} from '@devexpress/dx-react-grid-material-ui';
import {ActionButton, ActiveIndicator, Layout} from "common/components";
import {Post} from "../../posts/models";

interface WithCityId {
    area_id: number
    city_id: number
}

interface RouteComponentProps<WithCityId> {
    match: match<WithCityId>;
    location: H.Location;
    history: H.History;
    staticContext?: any;
}

const getChildRows = (row: any, rootRows: any) => {
    if (row) {
        if (row.sectors && row.sectors.length !== 0) {
            return row.sectors;
        }
        return undefined;
    }
    return rootRows
};

const StatusFormatter = (props: any) => {
    return <ActiveIndicator active={props.value === 'publish'}/>;
};

const StatusTypeProvider = (props: any) => (
    <DataTypeProvider
        formatterComponent={StatusFormatter}
        {...props}
    />
);

export const CourtsPage = ({match}: RouteComponentProps<WithCityId>) => {
    const dispatch = useDispatch();
    useEffect( () => {
        const {city_id} = match.params;
        let f: CourtsFilters = {
            city_id: city_id
        };
        dispatch(courtsActions.fetchAll(f));
    },[dispatch, match.params]);

    const courts = useSelector((state: StateInterface) => state.courts.courts.data);
    const isLoading = useSelector((state: StateInterface) => state.courts.courts.isLoading);

    const [columns] = useState([
        { name: 'name', title: 'Название', getCellValue: (row: any) => row.name },
        { name: 'national_id', title: 'Государственный идентификатор', getCellValue: (row: any) => row.national_id },
        { name: 'status', title: 'Статус', getCellValue: (row: Post) => row.status },
        { name: '', title: '', getCellValue: (row: any) => {
            if (row.court_id) {
                return <ActionButton
                    icon={<EditIcon/>}
                    label={"Редактировать"}
                    url={`/areas/${match.params.area_id}/cities/${match.params.city_id}/courts/${row.court_id}/sectors/${row.id}`}/>
            }
            return <ActionButton
                icon={<EditIcon/>}
                label={"Редактировать"}
                url={`/areas/${match.params.area_id}/cities/${match.params.city_id}/courts/${row.id}`}/>
        }},
    ]);

    const [statusColumns] = useState(['status']);

    return (
        <Layout pageName={'Суды'}>
            {isLoading ?
                <LinearProgress/>
                :
                <>
                    {courts.length === 0 ?
                        <h3>Пусто</h3> :
                        <Grid
                            rows={courts}
                            columns={columns}
                        >
                            <FilteringState/>
                            <TreeDataState />
                            <CustomTreeData
                                getChildRows={getChildRows}
                            />
                            <Table/>
                            <StatusTypeProvider for={statusColumns} />
                            <TableHeaderRow />
                            <TableTreeColumn
                                for="name"
                            />
                        </Grid>
                    }
                </>
            }

        </Layout>
    );
};

