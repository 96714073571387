import * as React from 'react';
import styles from "common/styles";
import {createStyles, makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(() => createStyles({
    infoBlock: {
        marginBottom: "1rem",
    },
    infoTitle: {
        textTransform: "uppercase",
        backgroundColor: styles.brandDarkColor,
        color: "white",
        textAlign: "center",
        padding: "0.2rem",
        fontSize: "initial",
        margin: 0,
        fontWeight: "initial"
    },
    content: {
        //marginTop: "0.5rem",
        padding: "0.5rem",
        overflow: "auto"
    }
}));

interface Props {
    label: string;
    children: any;
    onClose?: any;
}

export const InfoBlock: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.infoBlock}>
            <h3 className={classes.infoTitle}>
                {props.label}
            </h3>
            <div className={classes.content}>
                {props.children}
            </div>
        </div>
    );
};
