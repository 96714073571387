import Header from '@editorjs/header';
import List from '@editorjs/list';
import InlineCode from '@editorjs/inline-code';
import Image from '@editorjs/image';
import Embed from '@editorjs/embed';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import Code from '@editorjs/code';
import Link from '@editorjs/link';
import Delimiter from '@editorjs/delimiter';
import Raw from '@editorjs/raw';
import Table from '@editorjs/table';
import Warning from '@editorjs/warning';
import Paragraph from '@editorjs/paragraph';
import Checklist from '@editorjs/checklist';
import {filesActions} from "../../store/actions";
//import {filesActions} from "common/store/actions";

export default {
    header: Header,
    list: List,
    image: {
        class: Image,
        config: {
            uploader: {
                uploadByFile(file) {
                    return filesActions.uploadImageFromEditor(file);
                }
            }
        }
    },
    inlineCode: InlineCode,
    embed: Embed,
    quote: Quote,
    marker: Marker,
    code: Code,
    linkTool: Link,
    delimiter: Delimiter,
    raw: Raw,
    table: Table,
    warning: Warning,
    paragraph: Paragraph,
    checklist: Checklist,
};