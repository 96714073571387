import {AuthState} from "users/store/states";
import {types} from "users/store/actions/types";

const initialState = (): AuthState => ({
    isAuthenticated: (localStorage.getItem('jwt_access_token') !== null && localStorage.getItem('jwt_refresh_token') !== null),
});

export const AuthReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.AUTH_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticated: true,
            });
        case types.AUTH_LOGOUT:
            return Object.assign({}, state, {
                isAuthenticated: false,
            });
    }
    return state;
};