import ajax from '@codexteam/ajax';
require('./index.css').toString();

export default class Attachment {
    /**
     * Get Tool toolbox settings
     * icon - Tool icon's SVG
     * title - title to show in toolbox
     *
     * @return {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15pt" height="15pt" viewBox="0 0 15 15" version="1.1">
                <g id="surface1">
                <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 14.363281 5.464844 C 14.121094 5.21875 13.722656 5.21875 13.480469 5.464844 L 5.96875 13.019531 C 4.992188 13.992188 3.410156 13.992188 2.433594 13.019531 C 1.457031 12.042969 1.457031 10.457031 2.433594 9.484375 L 10.167969 1.707031 C 10.777344 1.097656 11.765625 1.097656 12.378906 1.707031 C 12.988281 2.316406 12.988281 3.308594 12.378906 3.917969 L 5.96875 10.324219 C 5.96875 10.324219 5.96875 10.328125 5.96875 10.328125 C 5.722656 10.570312 5.328125 10.570312 5.085938 10.324219 C 4.839844 10.082031 4.839844 9.6875 5.085938 9.441406 L 8.179688 6.347656 C 8.421875 6.105469 8.421875 5.707031 8.179688 5.464844 C 7.933594 5.21875 7.539062 5.21875 7.292969 5.464844 L 4.203125 8.558594 C 3.46875 9.289062 3.46875 10.476562 4.203125 11.210938 C 4.933594 11.941406 6.121094 11.941406 6.851562 11.210938 C 6.855469 11.207031 6.855469 11.207031 6.855469 11.207031 L 13.261719 4.800781 C 14.359375 3.703125 14.359375 1.921875 13.261719 0.824219 C 12.164062 -0.273438 10.382812 -0.273438 9.285156 0.824219 L 1.546875 8.601562 C 0.0859375 10.0625 0.0859375 12.4375 1.550781 13.902344 C 3.015625 15.367188 5.386719 15.367188 6.851562 13.902344 L 14.367188 6.347656 C 14.609375 6.101562 14.609375 5.707031 14.363281 5.464844 Z M 14.363281 5.464844 "/>
                </g>
            </svg>`,
            title: 'Вложение'
        };
    }

    /**
     * Allow Quote to be converted to/from other blocks
     */
    static get conversionConfig(){
        return {
            /**
             * To create Quote data from string, simple fill 'text' property
             */
            import: 'text',
            /**
             * To create string from Quote data, concatenate text and caption
             * @param {QuoteData} quoteData
             * @return {string}
             */
            export: function (quoteData) {
                return quoteData.text;
            }
        };
    }

    /**
     * Tool`s styles
     *
     * @returns {{baseClass: string, wrapper: string, quote: string, input: string, caption: string, settingsButton: string, settingsButtonActive: string}}
     */
    get CSS() {
        return {
            baseClass: this.api.styles.block,
            button: this.api.styles.button,
            widget: 'widget',
            uploadBtn: 'uploadBtn',
            previewIcon: 'previewIcon',
            wrapper: 'cdx-quote',
            text: 'customIconAndLink-text',
            input: this.api.styles.input,
            url: 'cdx-url',
            uploader: 'customIconAndLink-IconUploader',
            settingsWrapper: 'cdx-quote-settings',
            settingsButton: this.api.styles.settingsButton,
            settingsButtonActive: this.api.styles.settingsButtonActive,
            typeSelector: 'cdx-quote__variant-selector',
            blockHeadline: 'cdx-dynamicContent__headline',
            attachment: 'cdx-attachment',
        };
    }

    /**
     * Render plugin`s main Element and fill it with saved data
     *
     * @param {{data: QuoteData, config: QuoteConfig, api: object}}
     *   data — previously saved data
     *   config - user config for Tool
     *   api - Editor.js API
     */
    constructor({data, config, api}) {
        this.api = api;
        this.config = config;

        this.data = {
            text: data.text || '',
            url: data.url,
        };

        const text = this._make('div', [this.CSS.input, this.CSS.text],{
            contentEditable: true,
            innerHTML: this.data.text
        });

        text.dataset.placeholder = 'Текст ссылки';

        this.nodes = {
            button: null,
            wrapper: this._make('div', [this.CSS.baseClass, this.CSS.widget]),
            uploader: this._make('div', [this.CSS.uploader]),
            text: text,
        };

        const blockHeadline = this._make('span', [this.CSS.blockHeadline], {
            innerHTML: 'Файл'
        });
        this.nodes.wrapper.appendChild(blockHeadline);
        this.nodes.wrapper.appendChild(this.nodes.uploader);
        this.nodes.wrapper.appendChild(this.nodes.text);

        this.enableFileUpload = this.enableFileUpload.bind(this);
        this.renderUploader = this.renderUploader.bind(this);
        this.renderUploader();
    }

    renderUploader() {
        this.nodes.uploader.innerHTML = '';

        const uploadBtn = this._make('div', [this.CSS.button, this.CSS.uploadBtn], {
            innerHTML: 'Выбрать файл'
        });
        uploadBtn.addEventListener('click', this.enableFileUpload);
        this.nodes.uploader.appendChild(uploadBtn);

        if (this.data.url) {
            const a = this._make('a', [this.CSS.attachment], {
                href: process.env.REACT_APP_DOMAIN + this.data.url,
                target: "_blank"
            });

            let filename = this.data.url.split('/').pop();
            a.appendChild(document.createTextNode(filename));
            this.nodes.uploader.appendChild(a);
        }
    }

    enableFileUpload() {
        const upload = async () => {
            const upl  = ajax.selectFiles().then((files) => {
                const customUpload = this.config.uploader.uploadByFile(files[0]);

                if (!isPromise(customUpload)) {
                    console.warn('Custom uploader method uploadByFile should return a Promise');
                }
                return customUpload;
            }).catch((error) => {
                this.api.notifier.show({
                    message: "Что-то пошло не так",
                    style: 'error'
                });
            });
            this.data.url = (await upl).file.source;
            this.renderUploader();
        };

        upload();

    }

    /**
     * Create Quote Tool container with inputs
     *
     * @returns {Element}
     */
    render(data) {
        return this.nodes.wrapper;
    }

    /**
     * Extract Quote data from Quote Tool element
     *
     * @param {HTMLDivElement} quoteElement - element to save
     * @returns {QuoteData}
     */
    save(quoteElement) {
        const text = quoteElement.querySelector(`.${this.CSS.text}`);

        return Object.assign(this.data, {
            text: text.innerHTML,
            url: this.data.url
        });
    }

    /**
     * Sanitizer rules
     */
    static get sanitize() {
        return {
            text: {
                br: true,
            },
        };
    }

    /**
     * Helper for making Elements with attributes
     *
     * @param  {string} tagName           - new Element tag name
     * @param  {array|string} classNames  - list or name of CSS classname(s)
     * @param  {Object} attributes        - any attributes
     * @return {Element}
     */
    _make(tagName, classNames = null, attributes = {}) {
        let el = document.createElement(tagName);

        if ( Array.isArray(classNames) ) {
            el.classList.add(...classNames);
        } else if( classNames ) {
            el.classList.add(classNames);
        }

        for (let attrName in attributes) {
            el[attrName] = attributes[attrName];
        }

        return el;
    }
}

function isPromise(object) {
    return Promise.resolve(object) === object;
}
