import * as React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {LoginPage} from "users/components";
import {CourtEditPage, CourtsPage, SectorEditPage} from "courts/components";
import {useSelector} from "react-redux";
import {StateInterface} from "reducers";
import {AreasPage} from "./AreasPage";
import {PostsPage, PostsEditPage} from "posts/components";

export const Router: React.FC = () => {
    const isAuthenticated = useSelector((state: StateInterface) => state.users.auth.isAuthenticated);
    return (
        <>
            <Switch>
                <Route path="/login" component={LoginPage} />
                <Route exact path="/areas/:area_id/cities/:city_id/courts/:court_id/sectors/:sector_id" component={SectorEditPage} />
                <Route exact path="/areas/:area_id/cities/:city_id/courts/:court_id" component={CourtEditPage} />
                <Route exact path="/areas/:area_id/cities/:city_id/courts" component={CourtsPage} />
                <Route exact path="/areas" component={AreasPage} />
                <Route exact path="/posts/:id" component={PostsEditPage} />
                <Route exact path="/posts" component={PostsPage} />
                <Redirect from='/' to='/areas' />
            </Switch>
            {!isAuthenticated && <Redirect to={"/login"}/>}
        </>
    );
};
