import * as React from 'react';
import {Field, Form} from "react-final-form";
import commonStyles from "common/styles";
import {makeStyles} from '@material-ui/styles';
import {Button, createStyles, Theme} from "@material-ui/core";
import 'react-sortable-tree/style.css';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import {FieldError} from "common/components";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {ContactSchedule, ContactTypes, DaySchedule} from "../models";
import Grid from "@material-ui/core/Grid";
import 'moment/locale/ru';
import cogoToast from 'cogo-toast';


const moment = require('moment');

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    miniBtn: {
        padding: 0,
        minWidth: 'initial',
        textTransform: 'initial'
    }
}));

interface AddContactPopupProps {
    onSave: (values: ContactSchedule[]) => any,
    handleClose: () => any
}

const required = (value: any) => (value ? undefined : "Обязательное поле");

let currentTime = moment("09:00", "HH:mm");
let workTimes: any[] = [];
do {
    workTimes.push(currentTime.format('HH:mm'));
    currentTime = currentTime.add(15, 'minutes');
} while (currentTime.format('HH:mm') !== '19:00');

currentTime = moment("10:00", "HH:mm");
let breakTimes: any[] = [];
do {
    breakTimes.push(currentTime.format('HH:mm'));
    currentTime = currentTime.add(15, 'minutes');
} while (currentTime.format('HH:mm') !== '16:00');

export const NewContactSchedule: React.FC<AddContactPopupProps> = (props: AddContactPopupProps) => {
    const classes = useStyles();

    const onSubmit = (values: any) => {
        let sched: ContactSchedule = {
            type: ContactTypes.Schedule,
            title: values.title,
            text: values.text,
            schedule: []
        };

        const daysLines = values.days.split(/\r?\n/).filter((word: string) => word.length > 0);
        const workingHoursLines = values.working_hours.split(/\r?\n/).filter((word: string) => word.length > 0);
        const breaksLines = values.breaks.split(/\r?\n/).filter((word: string) => word.length > 0);
        if (daysLines.length !== workingHoursLines.length) {
            cogoToast.error('Разное количество строк', {position: 'bottom-left'});
            return;
        }

        let schedules: DaySchedule[] = [];
        for (let i = 0; i < daysLines.length; i++) {
            schedules.push({
                days: daysLines[i],
                working_hours: workingHoursLines[i],
                breaks: breaksLines[i]
            });
        }
        sched.schedule = schedules;
        props.onSave([sched]);
    };

    const onChange = (input: any, value: string) => {
        let lines = input.value?input.value.split(/\r?\n/):[];
        const lastLine = lines.length !== 0 ? lines[lines.length - 1]: "";

        if (value === 'Без перерыва') {
            if (lines.length === 0) {
                lines.push(value + '\n');
            } else {
                lines[lines.length - 1] = value + '\n';
            }
        } else {
            if (lastLine.length === 0) {
                if (lines.length === 0) {
                    lines.push(value);
                } else {
                    lines[lines.length - 1] = value;
                }
            } else {
                lines[lines.length - 1] = lines[lines.length - 1] + "-" + value + '\n';
            }
        }
        input.onChange(lines.join('\n'));
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{
                'title': 'Режим работы'
            }}
            render={({ handleSubmit, hasValidationErrors }) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <Dialog
                        open={true}
                        onClose={props.handleClose}
                    >
                        <form onSubmit={handleSubmit}>
                            <DialogTitle>{"Расписание"}</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            name="title"
                                            component="input"
                                            validate={required}
                                            type="text"
                                            required
                                        >
                                            {({ input, meta, ...rest }) => (
                                                <FormControl className={classes.formControl}>
                                                    <TextField
                                                        label="Режим работы"
                                                        {...input} {...rest}
                                                        error={!!meta.error || !!meta.submitError}
                                                    />
                                                    {(meta.error || meta.submitError) && meta.touched && (
                                                        <FieldError text={meta.error || meta.submitError}/>
                                                    )}
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            name="days"
                                            component="input"
                                            validate={required}
                                            type="text"
                                        >
                                            {({ input, meta, ...rest }) => {

                                                return <>
                                                    <FormControl className={classes.formControl}>
                                                        <TextField
                                                            label="Дни"
                                                            fullWidth
                                                            multiline
                                                            rows="7"
                                                            variant="outlined"
                                                            margin="normal"
                                                            {...input} {...rest}
                                                            error={!!meta.error || !!meta.submitError}
                                                        />
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <FieldError text={meta.error || meta.submitError}/>
                                                        )}
                                                    </FormControl>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                onClick={() => onChange(input, "Пн")}
                                                                className={classes.miniBtn}
                                                            >Понедельник</Button>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                onClick={() => onChange(input, "Вт")}
                                                                className={classes.miniBtn}
                                                            >Вторник</Button>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                onClick={() => onChange(input, "Ср")}
                                                                className={classes.miniBtn}
                                                            >Среда</Button>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                onClick={() => onChange(input, "Чт")}
                                                                className={classes.miniBtn}
                                                            >Четверг</Button>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                onClick={() => onChange(input, "Пт")}
                                                                className={classes.miniBtn}
                                                            >Пятница</Button>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            name="working_hours"
                                            component="input"
                                            validate={required}
                                            type="text"
                                        >
                                            {({ input, meta, ...rest }) => (
                                                <>
                                                    <FormControl className={classes.formControl}>
                                                        <TextField
                                                            label="Время работы"
                                                            fullWidth
                                                            multiline
                                                            rows="7"
                                                            variant="outlined"
                                                            margin="normal"
                                                            {...input} {...rest}
                                                            error={!!meta.error || !!meta.submitError}
                                                        />
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <FieldError text={meta.error || meta.submitError}/>
                                                        )}
                                                    </FormControl>
                                                    <Grid container spacing={1}>
                                                        {workTimes.map((time: string, index) =>
                                                            <Grid item xs={3} key={index}>
                                                                <Button
                                                                    onClick={() => onChange(input, time)}
                                                                    className={classes.miniBtn}
                                                                >{time}</Button>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            name="breaks"
                                            component="input"
                                            validate={required}
                                            type="text"
                                        >
                                            {({ input, meta, ...rest }) => (
                                                <>
                                                    <FormControl className={classes.formControl}>
                                                        <TextField
                                                            label="Перерыв"
                                                            fullWidth
                                                            multiline
                                                            rows="7"
                                                            variant="outlined"
                                                            margin="normal"
                                                            {...input} {...rest}
                                                            error={!!meta.error || !!meta.submitError}
                                                        />
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <FieldError text={meta.error || meta.submitError}/>
                                                        )}
                                                    </FormControl>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Button
                                                                onClick={() => onChange(input, 'Без перерыва')}
                                                                className={classes.miniBtn}
                                                            >{'Без перерыва'}</Button>
                                                        </Grid>
                                                        {breakTimes.map((time: string, index) =>
                                                            <Grid item xs={3} key={index}>
                                                                <Button
                                                                    onClick={() => onChange(input, time)}
                                                                    className={classes.miniBtn}
                                                                >{time}</Button>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </>
                                            )}
                                        </Field>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="text"
                                            component="input"
                                            type="text"
                                        >
                                            {({ input, meta, ...rest }) => (
                                                <FormControl className={classes.formControl}>
                                                    <TextField
                                                        label="Текст"
                                                        {...input} {...rest}
                                                        error={!!meta.error || !!meta.submitError}
                                                    />
                                                    {(meta.error || meta.submitError) && meta.touched && (
                                                        <FieldError text={meta.error || meta.submitError}/>
                                                    )}
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    disabled={hasValidationErrors}
                                    variant="contained"
                                    color="primary"
                                    onClick={event => {
                                        submit(event);
                                    }}
                                >
                                    Добавить
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={event => {
                                        props.handleClose();
                                    }}
                                >
                                    Отмена
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                )
        }}/>
    );
};