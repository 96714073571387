import * as React from 'react';
import {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from "@material-ui/core/Button";
import {Field, Form} from "react-final-form";
import {Redirect} from "react-router";
import {useDispatch} from 'react-redux';
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import commonStyles from "common/styles";
import {authActions} from "../store/actions";
import {FieldError} from "common/components";

import Banner from './banner.jpg';

const useStyles = makeStyles({
    formControl: {
        ...commonStyles.formControl
    },
    wrapper: {
        background: `url(${Banner}) no-repeat 50% fixed`,
        backgroundSize: "cover",
        opacity: "0.4 !important" as any,
    },
});

const required = (value: string) => value ? undefined : "Это поле обязательно";
const composeValidators = (...validators: any) => (value: string) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

export const LoginPage: React.FC = () => {
    const dispatch = useDispatch();

    const onSubmit = async (values: any) => {
        try {
            await dispatch(authActions.login(values.username, values.password));
        } catch (e) {
            return e;
        }
        setIsAuth(true);
    };

    const classes = useStyles();
    const [isAuth, setIsAuth] = useState(false);

    let submit: any;
    return (
        <>
            {isAuth && <Redirect to="/"/>}
            <Dialog open={true} BackdropProps={{
                classes: {
                    root: classes.wrapper
                }
            }}>
                <DialogTitle>{"Авторизация"}</DialogTitle>
                <DialogContent>
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit, submitError }) => {
                            submit = handleSubmit;
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Field
                                        name="username"
                                        component="input"
                                        type="text"
                                        validate={composeValidators(required)}
                                        required
                                    >
                                        {({ input, meta, ...rest }) => (
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    autoFocus
                                                    label="Логин"
                                                    {...input} {...rest}
                                                    error={!!meta.error || !!meta.submitError}
                                                />
                                                {(meta.error || meta.submitError) && meta.touched && (
                                                    <FieldError text={meta.error || meta.submitError}/>
                                                )}
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field
                                        name="password"
                                        component="input"
                                        type="password"
                                        validate={composeValidators(required)}
                                        required
                                    >
                                        {({ input, meta, ...rest }) => (
                                            <FormControl className={classes.formControl}>
                                                <TextField
                                                    label="Пароль"
                                                    {...input} {...rest}
                                                    error={!!meta.error || !!meta.submitError}
                                                />
                                                {(meta.error || meta.submitError) && meta.touched && (
                                                    <FieldError text={meta.error || meta.submitError}/>
                                                )}
                                            </FormControl>
                                        )}
                                    </Field>
                                    {submitError && <FieldError text={submitError} />}
                                </form>
                            )
                        }}/>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={event => {
                            submit(event);
                        }}
                    >
                        {"Войти"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};