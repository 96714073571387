import {types} from './types';
import {Sector} from "courts/models";
import {helpers} from "common/helpers";
import {Error} from "common/models";
import * as HttpStatus from "http-status-codes";
import {FORM_ERROR} from "final-form";
import cogoToast from 'cogo-toast';
import {ApiClient} from "../../../common/api-client";

const savePreview = (sector: Sector) => () => {
    let data = Object.assign({}, sector) as any;

    return ApiClient.post(`/sectors/${sector.id}/save_preview`, data)
        .then(function (response: any) {
            return response.data
        })
        .catch(function (error: any) {
            console.dir(error);
            let errors: Error = {};
            if (error.response.status === HttpStatus.BAD_REQUEST) {
                for (let [key, value] of Object.entries(error.response.data)) {
                    errors[key === 'non_field_errors' ? FORM_ERROR : key] = (value as string[])[0];
                }
            } else {
                errors[FORM_ERROR] = "Что-то пошло не так... Код: " + error.response.status;
            }

            return Promise.reject(errors);
        });
};


const fetch = (id: number) => (dispatch: any) => {
    dispatch(sectorLoadingProcess());

    return ApiClient.get(`/courts/sectors/${id}/`)
        .then((response: any) => {
            dispatch(sectorLoadingSuccess(response.data.data as Sector));
        })
        .catch(function (error: any) {
            console.dir(error);
        });
};


const sectorLoadingProcess = () => ({
    type: types.SECTOR_LOADING_PROCESS
});

const sectorLoadingSuccess = (sector: Sector) => ({
    type: types.SECTOR_LOADING_SUCCESS,
    payload: {
        data: sector,
    }
});

const update = (sector: Sector) => (dispatch: any) => {
    let data = Object.assign({}, sector) as any;

    return ApiClient.put( `/courts/sectors/${sector.id}/`, data)
        .then(function (response: any) {
            cogoToast.success('Изменения сохранены', {position: 'bottom-left'});
            return response.data
        })
        .catch(function (error: any) {
            cogoToast.error('При сохранении возникли ошибки', {position: 'bottom-left'});
            let errors: Error = {};
            if (error.response.status === HttpStatus.BAD_REQUEST) {
                errors = helpers.processErrors(error.response.data);
            } else {
                errors[FORM_ERROR] = "Что-то пошло не так... Код: " + error.response.status;
            }

            return Promise.reject(errors);
        });
};

export const sectorsActions = {
    savePreview,
    update,
    fetch,
};