import * as React from 'react';
import {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button, createStyles, Theme} from "@material-ui/core";
import 'react-sortable-tree/style.css';
import {NewContactEmail} from "./NewContactEmail";
import {NewContactAddress} from "./NewContactAddress";
import {NewContactSchedule} from "./NewContactSchedule";
import {NewContactCabinet} from "./NewContactCabinet";
import {NewContactPhone} from "./NewContactPhone";
import {NewContactMan} from "./NewContactMan";
import {NewContactDepartment} from "./NewContactDepartment";
import {NewContactSet} from "./NewContactSet";
import {Contact} from "../models";
import {City} from "common/models";
import {NewContactSite} from "./NewContactSite";

const useStyles = makeStyles((theme: Theme) => createStyles({
    newContactBtn: {
        marginLeft: "0.5rem"
    },
    panel: {
        marginBottom: "0.5rem"
    }
}));

interface NewContactsProps {
    site: string;
    city: City;
    onAdd: (values: Contact[]) => any,
}

export const NewContacts: React.FC<NewContactsProps> = (props: NewContactsProps) => {
    const classes = useStyles();

    interface AddBtn {
        type: string;
        name: string;
    }

    const [activePopup, setActivePopup] = useState<string | null>(null);

    const newContactButtons: AddBtn[] = [{
        "name": "Набор данных",
        "type": "set"
    },{
        "name": "Отдел",
        "type": "department"
    },{
        "name": "Человек",
        "type": "man"
    },{
        "name": "Телефон",
        "type": "phone"
    },{
        "name": "Кабинет",
        "type": "cabinet"
    },{
        "name": "Расписание",
        "type": "schedule"
    },{
        "name": "Адрес",
        "type": "address"
    },{
        "name": "Почта",
        "type": "email"
    },{
        "name": "Сайт",
        "type": "site"
    },];

    const addContacts = (contacts: Contact[]) => {
        setActivePopup(null);
        props.onAdd(contacts);
    };

    return (
        <div className={classes.panel}>
            <span>Добавить</span>
            {newContactButtons.map((btn: AddBtn, i: number) => {
                return <Button
                    key={i}
                    className={classes.newContactBtn}
                    variant="contained"
                    color="primary"
                    onClick={() => setActivePopup(btn.type)}
                    size="small">{btn.name}</Button>;
            })}
            {activePopup === 'set' &&
            <NewContactSet
                city={props.city}
                onSave={addContacts}
                handleClose={() => setActivePopup(null)}
            />
            }
            {activePopup === 'department' &&
            <NewContactDepartment
                onSave={addContacts}
                handleClose={() => setActivePopup(null)}
            />
            }
            {activePopup === 'man' &&
            <NewContactMan
                onSave={addContacts}
                handleClose={() => setActivePopup(null)}
            />
            }
            {activePopup === 'phone' &&
            <NewContactPhone
                city={props.city}
                onSave={addContacts}
                handleClose={() => setActivePopup(null)}
            />
            }
            {activePopup === 'cabinet' &&
            <NewContactCabinet
                onSave={addContacts}
                handleClose={() => setActivePopup(null)}
            />
            }
            {activePopup === 'schedule' &&
            <NewContactSchedule
                onSave={addContacts}
                handleClose={() => setActivePopup(null)}
            />
            }
            {activePopup === 'address' &&
            <NewContactAddress
                onSave={addContacts}
                handleClose={() => setActivePopup(null)}
            />
            }
            {activePopup === 'email' &&
            <NewContactEmail
                onSave={addContacts}
                handleClose={() => setActivePopup(null)}
            />
            }
            {activePopup === 'site' &&
            <NewContactSite
                site={props.site}
                onSave={addContacts}
                handleClose={() => setActivePopup(null)}
            />
            }
        </div>
    );
};