import * as React from 'react';
import {Form} from "react-final-form";
import {Button} from "@material-ui/core";
import {Contact, Court, Sector} from "../models";
import Divider from "@material-ui/core/Divider";
import 'react-sortable-tree/style.css';
import Grid from "@material-ui/core/Grid";
import {externalNodeType} from "./ContactRenderResolver";
import {Choice, FormBlock, SelectField, TreeField} from "common/components";
import {makeStyles} from "@material-ui/styles";
import styles from "common/styles/styles";
import {sectorsActions} from "../store/actions";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {useDispatch} from "react-redux";


interface CourtSectorFormProps {
    initial: Sector,
    onSubmit: (values: any) => any,
}

interface TreeNode {
    contact: Contact;
    title: string;
    subtitle?: string;
    expanded?: boolean;
    children?: TreeNode[];
}

const useStyles = makeStyles({
    link: {
        ...styles.link,
        marginLeft: '1rem'
    },
    draftWidget: {
        padding: '0.5rem',
        border: `1px solid ${styles.grayColor}`,
        borderRadius: '3px'
    },
    draftTitle: {
        marginTop: 0
    }
});

export const SectorForm: React.FC<CourtSectorFormProps> = (props: CourtSectorFormProps) => {
    let submit: (values: any) => any;

    const classes = useStyles();
    const dispatch = useDispatch();

    let initial: Sector = props.initial;

    const savePreview = async (sector: Sector) => {
        // @ts-ignore
        const preview: Court = await dispatch(sectorsActions.savePreview(sector));
        window.open(`${process.env.REACT_APP_DOMAIN}/previews/${initial.city.slug}/sectors/${preview.preview_token}/`, '_blank');
    };

    return (
        <Form
            onSubmit={props.onSubmit}
            initialValues={initial}
            render={({ handleSubmit, values, form }) => {
                submit = handleSubmit;

                return (
                    <>
                        <form onSubmit={handleSubmit}>
                            <FormBlock label={"Основные контакты"}>
                                <TreeField
                                    label={"Контакты"}
                                    city={initial.city}
                                    site={initial.site}
                                    name={"contacts_main"}
                                    externalNodeType={externalNodeType}/>
                            </FormBlock>
                            <Divider/>
                            <FormBlock label={"Состав суда"}>
                                <TreeField
                                    label={"Контакты"}
                                    city={initial.city}
                                    site={initial.site}
                                    name={"contacts_court_composition"}
                                    externalNodeType={externalNodeType}/>
                            </FormBlock>
                        </form>

                        <Divider/>
                        <>
                            <h2 className={classes.draftTitle}>Черновик</h2>
                            <div className={classes.draftWidget}>
                                <TreeField
                                    label={"Люди и отделы"}
                                    city={initial.city}
                                    site={initial.site}
                                    name={"draft_contacts"}
                                    allowCreate={true}
                                    externalNodeType={externalNodeType}
                                    allowDelete
                                />
                            </div>
                        </>
                        <br/>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <SelectField
                                    name={"status"}
                                    label={"Статус"}
                                    choices={[
                                        {
                                            key: 'publish',
                                            value: 'Опубликовано'
                                        },
                                        {
                                            key: 'draft',
                                            value: 'Черновик'
                                        },
                                    ] as Choice[]}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <br/>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={event => {
                                submit(event);
                            }}
                        >
                            Сохранить
                        </Button>
                        {initial && initial.site && <a
                            className={classes.link}
                            rel="noopener noreferrer"
                            target={"_blank"}
                            href={initial.site.startsWith('http')?initial.site : ("//" + initial.site)}>{initial.site}</a>}
                        <Button
                            size={"small"}
                            onClick={event => {
                                savePreview(form.getState().values);
                            }}
                            color={"primary"}>
                            <VisibilityIcon/>
                        </Button>
                    </>
                )
            }
        }/>
    );
};