import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Grid} from "@material-ui/core";
import {PostForm} from "./PostForm";
import {match} from "react-router-dom";
import * as H from 'history';
import {postsActions} from "../store/actions";
import {StateInterface} from "reducers";
import {Post} from "posts/models";
import {Layout, Loadable} from "common/components";
import LinearProgress from "@material-ui/core/LinearProgress";
import cogoToast from "cogo-toast";


/*const useStyles = makeStyles((theme: Theme) => createStyles({

}));*/

interface WithId {
    id: number
}

export interface RouteComponentProps<WithId> {
    match: match<WithId>;
    location: H.Location;
    history: H.History;
    staticContext?: any;
}

export const PostsEditPage = ({match}: RouteComponentProps<WithId>) => {
    /*const dispatch = useDispatch();
    const classes = useStyles();*/

    const dispatch = useDispatch();

    const post = useSelector((state: StateInterface) => state.posts.post.data);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(postsActions.fetchAll());
        const fetchData = async () => {
            try {
                const {id} = match.params;
                await dispatch(postsActions.fetch(id));
            } catch (e) {
                console.dir(e.error);
            }
            setIsLoading(false);
        };
        setIsLoading(true);
        fetchData();

        window.scrollTo(0, 0);

    }, [dispatch, match.params]);

    const [isSaving, setIsSaving] = useState(false);
    const posts = useSelector((state: StateInterface) => state.posts.posts.data);

    const onsubmit = async (post: Post) => {
        setIsSaving(true);

        for (let i = 0; i < post.content.blocks.length; i++) {
            if (post.content.blocks[i].type === 'image') {
                let block = post.content.blocks[i];
                if (!block.data.file.url) {
                    continue;
                }
                block.data.file.url = block.data.file.url.replace(process.env.REACT_APP_DOMAIN, '');
                post.content.blocks[i] = block;
            }

            if (post.content.blocks[i].type === 'iconAndLink' || post.content.blocks[i].type === 'attachment') {
                let block = post.content.blocks[i];
                block.data.url = block.data.url.replace(process.env.REACT_APP_DOMAIN, '')
            }
        }

        try {
            await dispatch(postsActions.update(post));
            cogoToast.success('Изменения сохранены', {position: 'bottom-left'});
            await dispatch(postsActions.fetchAll());
            setIsSaving(false);
        } catch (e) {
            cogoToast.error('При сохранении возникли ошибки', {position: 'bottom-left'});
            setIsSaving(false);
            return e;
        }
    };

    return (
        <Layout pageName={"Редактирование статьи"}>
            {isLoading ? <LinearProgress/> :
                <Grid container spacing={1}>
                    <Grid item xs={8} md={8}>
                        {post && (
                            <Loadable isLoading={isSaving}>
                                <PostForm
                                    onSubmit={onsubmit}
                                    initial={post}
                                    posts={posts}
                                />
                            </Loadable>
                        )}
                    </Grid>
                    <Grid item xs={4} md={4}>

                    </Grid>
                </Grid>
            }
        </Layout>
    );
};

