import * as React from 'react';
import 'react-sortable-tree/style.css';
import {ContactCardHeader} from "./ContactCardHeader";
import PhoneIcon from '@material-ui/icons/Phone';
import {ContactCard} from "./ContactCard";
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core";
import commonStyles from "common/styles/styles";
import {City} from "common/models";
import {helpers} from "../../common/helpers";

const classNames = require('classnames');

const useStyles = makeStyles((theme: Theme) => createStyles({
    invalid: {
        color: commonStyles.dangerColor,
    },
}));

interface Props {
    city: City;
    title: string
    number: string
    action: any
}

export const PhoneBlock: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const formatedPhone = helpers.phoneFormatter(props.number, props.city.code);
    return (
        <ContactCard>
            <ContactCardHeader
                avatar={
                    <PhoneIcon/>
                }
                title={props.title}
                subheader={
                    <span className={classNames({[classes.invalid]: formatedPhone === false})}>
                        {formatedPhone?formatedPhone : props.number}
                    </span>
                }
                action={props.action}
            />
        </ContactCard>
    );
};