const composeValidators = (...validators: any) => (value: string) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

const required = (value: string) => value ? undefined : "Это поле обязательно";

const maxLength = (max: number) => (value: string) =>
    (value === undefined || value.length < max) ? undefined : `Длина не должна превышать ${max} символов. Текущее значение имеет ${value.length} символов`;

const notEmptyEditorJS = (value: any) => value && value.blocks.length !== 0 ? undefined : "Это поле обязательно";

const isUrl = (value: string) => {
    try {
        new URL(value);
        return undefined;
    } catch (_) {
        return "Неправильный формат URL";
    }
};

export const validators = {
    composeValidators,
    required,
    maxLength,
    isUrl,
    notEmptyEditorJS
};