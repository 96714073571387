import * as React from 'react';
import {useState} from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {createStyles, makeStyles} from "@material-ui/styles";
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(() => createStyles({
    infoBlock: {
        marginBottom: "1rem",
    },
    infoTitle: {
        cursor: "pointer"
    },
    content: {
        //marginTop: "0.5rem",
        padding: "0.5rem",
        overflow: "auto"
    },
    icon: {
        float: "left"
    }
}));

interface Props {
    label: string;
    children: any;
}

export const FormBlock: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const [isVisible, setIsVisible] = useState<boolean>(false);

    return (
        <div className={classes.infoBlock}>
            <h3 className={classes.infoTitle} onClick={(ev: any) => setIsVisible(!isVisible)}>
                {isVisible?<>
                    <KeyboardArrowDownIcon className={classes.icon} /> {props.label}
                </>:
                <>
                    <KeyboardArrowRightIcon className={classes.icon} /> {props.label}
                </>}
            </h3>
            <Collapse in={isVisible}>
                <div className={classes.content}>
                    {props.children}
                </div>
            </Collapse>
        </div>
    );
};
