require('./index.css').toString();

/**
 * @class Quote
 * @classdesc Quote Tool for Editor.js
 * @property {QuoteData} data - Tool`s input and output data
 * @propert {object} api - Editor.js API instance
 *
 * @typedef {object} QuoteData
 * @description Quote Tool`s input and output data
 * @property {string} text - quote`s text
 * @property {string} headline - quote`s caption
 * @property {'center'|'left'} alignment - quote`s alignment
 *
 * @typedef {object} QuoteConfig
 * @description Quote Tool`s initial configuration
 * @property {string} quotePlaceholder - placeholder to show in quote`s text input
 * @property {string} captionPlaceholder - placeholder to show in quote`s caption input
 * @property {'center'|'left'} defaultAlignment - alignment to use as default
 */
class TitleAndText {
    /**
     * Get Tool toolbox settings
     * icon - Tool icon's SVG
     * title - title to show in toolbox
     *
     * @return {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            icon: '<svg width="15" height="14" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M14.5 2h-13c-0.825 0-1.5 0.675-1.5 1.5v9c0 0.825 0.675 1.5 1.5 1.5h13c0.825 0 1.5-0.675 1.5-1.5v-9c0-0.825-0.675-1.5-1.5-1.5zM1.5 3h13c0.271 0 0.5 0.229 0.5 0.5v1.5h-14v-1.5c0-0.271 0.229-0.5 0.5-0.5zM14.5 13h-13c-0.271 0-0.5-0.229-0.5-0.5v-4.5h14v4.5c0 0.271-0.229 0.5-0.5 0.5zM2 10h1v2h-1zM4 10h1v2h-1zM6 10h1v2h-1z"></path>\n' +
                '</svg>',
            title: 'Заголовок и текст'
        };
    }

    /**
     * Empty Quote is not empty Block
     * @public
     * @returns {boolean}
     */
    static get contentless() {
        return true;
    }

    /**
     * Allow to press Enter inside the Quote
     * @public
     * @returns {boolean}
     */
    static get enableLineBreaks() {
        return true;
    }

    /**
     * Default placeholder for quote text
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_QUOTE_PLACEHOLDER() {
        return 'Текст';
    }

    /**
     * Default placeholder for quote headline
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_HEADLINE_PLACEHOLDER() {
        return 'Заголовок';
    }

    /**
     * Allowed quote alignments
     *
     * @public
     * @returns {{left: string, center: string}}
     */
    static get ALIGNMENTS() {
        return {
            left: 'left',
            center: 'center'
        };
    }

    /**
     * Default quote alignment
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_ALIGNMENT() {
        return TitleAndText.ALIGNMENTS.left;
    }

    /**
     * Allow Quote to be converted to/from other blocks
     */
    static get conversionConfig(){
        return {
            /**
             * To create Quote data from string, simple fill 'text' property
             */
            import: 'text',
            /**
             * To create string from Quote data, concatenate text and caption
             * @param {QuoteData} quoteData
             * @return {string}
             */
            export: function (quoteData) {
                return quoteData.headline ? `${quoteData.text} — ${quoteData.headline}` : quoteData.text;
            }
        };
    }

    /**
     * Tool`s styles
     *
     * @returns {{baseClass: string, wrapper: string, quote: string, input: string, caption: string, settingsButton: string, settingsButtonActive: string}}
     */
    get CSS() {
        return {
            baseClass: this.api.styles.block,
            wrapper: 'cdx-quote',
            widget: 'widget',
            text: 'cdx-quote__text',
            input: this.api.styles.input,
            headline: 'cdx-quote__headline',
            settingsWrapper: 'cdx-quote-settings',
            settingsButton: this.api.styles.settingsButton,
            settingsButtonActive: this.api.styles.settingsButtonActive,
            variantSelector: 'cdx-quote__variant-selector'
        };
    }

    /**
     * Tool`s settings properties
     *
     * @returns {*[]}
     */
    get settings() {
        return [
            {
                name: 'left',
                icon: `<svg width="16" height="11" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg" ><path d="M1.069 0H13.33a1.069 1.069 0 0 1 0 2.138H1.07a1.069 1.069 0 1 1 0-2.138zm0 4.275H9.03a1.069 1.069 0 1 1 0 2.137H1.07a1.069 1.069 0 1 1 0-2.137zm0 4.275h9.812a1.069 1.069 0 0 1 0 2.137H1.07a1.069 1.069 0 0 1 0-2.137z" /></svg>`
            },
            {
                name: 'center',
                icon: `<svg width="16" height="11" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg" ><path d="M1.069 0H13.33a1.069 1.069 0 0 1 0 2.138H1.07a1.069 1.069 0 1 1 0-2.138zm3.15 4.275h5.962a1.069 1.069 0 0 1 0 2.137H4.22a1.069 1.069 0 1 1 0-2.137zM1.069 8.55H13.33a1.069 1.069 0 0 1 0 2.137H1.07a1.069 1.069 0 0 1 0-2.137z"/></svg>`
            }
        ];
    }

    /**
     * Render plugin`s main Element and fill it with saved data
     *
     * @param {{data: QuoteData, config: QuoteConfig, api: object}}
     *   data — previously saved data
     *   config - user config for Tool
     *   api - Editor.js API
     */
    constructor({data, config, api}) {
        const {ALIGNMENTS, DEFAULT_ALIGNMENT} = TitleAndText;

        this.api = api;

        this.quotePlaceholder = config.quotePlaceholder || TitleAndText.DEFAULT_QUOTE_PLACEHOLDER;
        this.captionPlaceholder = config.captionPlaceholder || TitleAndText.DEFAULT_HEADLINE_PLACEHOLDER;

        this.data = {
            headline: data.headline || '',
            text: data.text || '',
            variant: data.variant || '1',
            alignment: (Object.values(ALIGNMENTS).includes(data.alignment) && data.alignment) ||
                config.defaultAlignment ||
                DEFAULT_ALIGNMENT
        };
    }

    /**
     * Create Quote Tool container with inputs
     *
     * @returns {Element}
     */
    render() {
        const container = this._make('div', [this.CSS.baseClass, this.CSS.widget]);
        const quote = this._make('div', [this.CSS.input, this.CSS.text], {
            contentEditable: true,
            innerHTML: this.data.text
        });
        const headline = this._make('div', [this.CSS.input, this.CSS.headline], {
            contentEditable: true,
            innerHTML: this.data.headline
        });

        let variant = this._make('select', [this.CSS.input, this.CSS.variantSelector, this.CSS.caption]);

        for (let i = 1; i <= 2; i++) {
            const option = document.createElement("option");
            option.value = String(i);
            option.text = "Вариант " + String(i);
            variant.append(option);
        }

        variant.value = this.data.variant;

        let me = this;
        variant.onchange = () => {
            me.data =  Object.assign(me.data, {
                variant: variant.value
            });
        };

        quote.dataset.placeholder = this.quotePlaceholder;
        headline.dataset.placeholder = this.captionPlaceholder;

        container.appendChild(headline);
        container.appendChild(quote);
        container.appendChild(variant);

        return container;
    }

    /**
     * Extract Quote data from Quote Tool element
     *
     * @param {HTMLDivElement} quoteElement - element to save
     * @returns {QuoteData}
     */
    save(quoteElement) {
        const headline = quoteElement.querySelector(`.${this.CSS.headline}`);
        const text = quoteElement.querySelector(`.${this.CSS.text}`);

        return Object.assign(this.data, {
            headline: headline.innerHTML,
            text: text.innerHTML,
            variant: this.data.variant
        });
    }

    /**
     * Sanitizer rules
     */
    static get sanitize() {
        return {
            text: {
                br: true,
            },
            headline: {
                br: true,
            },
            alignment: {}
        };
    }

    /**
     * Create wrapper for Tool`s settings buttons:
     * 1. Left alignment
     * 2. Center alignment
     *
     * @returns {HTMLDivElement}
     */
    renderSettings() {
        const wrapper = this._make('div', [ this.CSS.settingsWrapper ], {});
        const capitalize = str => str[0].toUpperCase() + str.substr(1);

        this.settings
            .map( tune => {
                const el = this._make('div', this.CSS.settingsButton, {
                    innerHTML: tune.icon,
                    title: `${capitalize(tune.name)} alignment`
                });

                el.classList.toggle(this.CSS.settingsButtonActive, tune.name === this.data.alignment);

                wrapper.appendChild(el);

                return el;
            })
            .forEach((element, index, elements) => {
                element.addEventListener('click', () => {
                    this._toggleTune(this.settings[index].name);

                    elements.forEach((el, i) => {
                        const {name} = this.settings[i];

                        el.classList.toggle(this.CSS.settingsButtonActive, name === this.data.alignment);
                    });
                });
            });

        return wrapper;
    };

    /**
     * Toggle quote`s alignment
     *
     * @param {string} tune - alignment
     * @private
     */
    _toggleTune(tune) {
        this.data.alignment = tune;
    }

    /**
     * Helper for making Elements with attributes
     *
     * @param  {string} tagName           - new Element tag name
     * @param  {array|string} classNames  - list or name of CSS classname(s)
     * @param  {Object} attributes        - any attributes
     * @return {Element}
     */
    _make(tagName, classNames = null, attributes = {}) {
        let el = document.createElement(tagName);

        if ( Array.isArray(classNames) ) {
            el.classList.add(...classNames);
        } else if( classNames ) {
            el.classList.add(classNames);
        }

        for (let attrName in attributes) {
            el[attrName] = attributes[attrName];
        }

        return el;
    }
}

export default TitleAndText;