import withStyles from "@material-ui/core/styles/withStyles";
import {CardContent} from "@material-ui/core";

export const ContactCardContent = withStyles({
    root: {
        fontStyle: "italic",
        fontSize: "0.7rem",
        padding: "4px",
        paddingTop: "0px",
        "&:last-child": {
            paddingBottom: "4px"
        }
    },
})(CardContent);