import * as React from "react";

import {Route, Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import {store} from './store';
import {ThemeProvider} from '@material-ui/styles';
import green from '@material-ui/core/colors/green';
import {createMuiTheme} from '@material-ui/core/styles';
import {Router} from "common/components";
import {DndProvider} from "react-dnd";
import HTML5Backend from 'react-dnd-html5-backend';

const theme = createMuiTheme({
    palette: {
        primary: green,
    },
});

theme.typography.h1 = {
    fontSize: '1.5rem',
    marginBottom: '0.5rem'
} as any;

theme.typography.h2 = {
    fontSize: '1.4rem',
    marginBottom: '0.5rem'
} as any;

theme.typography.h3 = {
    fontSize: '1.3rem',
    marginBottom: '0.5rem'
} as any;

theme.typography.h4 = {
    fontSize: '1.2rem',
    marginBottom: '0.5rem'
} as any;

theme.typography.h5 = {
    fontSize: '1.1rem',
    marginBottom: '0.5rem'
} as any;

const App: React.FC = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={theme}>
              <Provider store={store}>
                <BrowserRouter>
                  <Switch>
                      <Route path="/" component={Router} />
                  </Switch>
                </BrowserRouter>
              </Provider>
            </ThemeProvider>
        </DndProvider>
    );
};

export default App;
