import * as React from 'react';
import {Button} from "@material-ui/core";
import {Form} from "react-final-form";
import {BlockTextField, Choice, FormBlock, SelectField, TextField} from "common/components";
import {Post} from "posts/models";
import Grid from "@material-ui/core/Grid";
import {helpers} from "common/helpers";
import createDecorator from "final-form-calculate";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {postsActions} from "../store/actions";
import {useDispatch} from "react-redux";
const slugify = require('slugify');

interface PostFormProps {
    initial?: Post | undefined,
    posts: Post[],
    onSubmit: (values: Post) => any,
    onChange?: (formData: any) => any,
}

const calculator = createDecorator(
    {
        field: 'headline_short', // when minimum changes...
        updates: {
            // ...update maximum to the result of this function
            slug: (value, allValues) => value?slugify(value).toLowerCase():''
        }
    },
);

export const PostForm: React.FC<PostFormProps> = (props: PostFormProps) => {
    let allowedParents: Choice[] = helpers.postsTree(props.posts);

    const dispatch = useDispatch();

    const savePreview = async (post: Post) => {
         // @ts-ignore
         const preview: Post = await dispatch(postsActions.savePreview(post));
         window.open(`${process.env.REACT_APP_DOMAIN}/previews/posts/${preview.preview_token}/`, '_blank');
    };

    // @ts-ignore
    let initial: Post | undefined = props.initial;
    if (initial && initial.content) {
        for (let i = 0; i < initial.content.blocks.length; i++) {
            if (initial.content.blocks[i].type === 'image') {
                let block = initial.content.blocks[i];
                if (!block.data.file.url) {
                    continue;
                }
                block.data.file.url = String(new URL(block.data.file.url, process.env.REACT_APP_DOMAIN));
                initial.content.blocks[i] = block;
            }
        }
    }

    return (
        <Form
            onSubmit={props.onSubmit}
            decorators={[calculator]}
            initialValues={initial}
            render={({handleSubmit, form}) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <>
                        <form onSubmit={handleSubmit}>
                            <FormBlock label={"SEO-настройки"}>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <TextField
                                            label={"Название полностью"}
                                            name={"headline"}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label={"Название коротко"}
                                            name={"headline_short"}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SelectField
                                            name={"parent_id"}
                                            label={"Расположение"}
                                            choices={allowedParents}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label={"Слаг"}
                                            name={"slug"}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={"Заголовок страницы (Title)"}
                                            name={"title"}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={"Описание страницы (Description)"}
                                            name={"description"}
                                            multiline
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </FormBlock>
                            <FormBlock label={"Настройки лидов"}>
                                <Grid container spacing={1}>
                                    <Grid item md={6}>
                                        <SelectField
                                            name={"lawyer_type"}
                                            label={"Адвокат или юрист"}
                                            choices={[
                                                {
                                                    key: 'lawyers',
                                                    value: 'Юристы'
                                                },
                                                {
                                                    key: 'advocates',
                                                    value: 'Адвокаты'
                                                },
                                                {
                                                    key: 'lawyers-and-advocates',
                                                    value: 'Юристы и адвокаты'
                                                },
                                            ] as Choice[]}
                                            required
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <SelectField
                                            name={"receiver"}
                                            label={"Получатель"}
                                            choices={[
                                                {
                                                    key: 'set',
                                                    value: 'Сеть Юристов'
                                                },
                                                {
                                                    key: 'partner',
                                                    value: 'Партнёр'
                                                },
                                            ] as Choice[]}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={"Подсказка"}
                                            name={"help_text"}
                                            multiline
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </FormBlock>
                            <TextField
                                label={"Название статьи"}
                                name={"headline"}
                                disabled
                            />
                            <BlockTextField name={"content"} label={"Текст статьи"} required/>
                            <SelectField
                                name={"status"}
                                label={"Статус"}
                                choices={[
                                    {
                                        key: 'publish',
                                        value: 'Опубликовано'
                                    },
                                    {
                                        key: 'draft',
                                        value: 'Черновик'
                                    },
                                ] as Choice[]}
                                required
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={event => {
                                    submit(event);
                                }}
                            >
                                Сохранить
                            </Button>
                            <Button
                                size={"small"}
                                onClick={event => {
                                    savePreview(form.getState().values);
                                }}
                                color={"primary"}>
                                <VisibilityIcon/>
                            </Button>
                        </form>
                    </>
                )
            }}/>
    );
};

