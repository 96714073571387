import * as React from 'react';
import 'react-sortable-tree/style.css';
import {makeStyles} from "@material-ui/styles";
import {Button, createStyles, Table, TableHead} from "@material-ui/core";
import {ContactSchedule, DaySchedule} from "../models";
import styles from "common/styles/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => createStyles({
    link: {
        cursor: 'pointer',
        color: styles.successColor,
        display: "inline-block",
        textDecoration: 'none',
        border: 'none',
        borderBottom: "1px dotted",
    }
}));

interface Props {
    schedule: ContactSchedule;
}

export const ScheduleTitle: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <>
            <div className={classes.link} onClick={() => setIsOpen(true)}>
                {props.schedule.title}
            </div>
            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <DialogTitle>{props.schedule.title}</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{"Дни"}</TableCell>
                                <TableCell>{"Время работы"}</TableCell>
                                <TableCell>{"Перерыв"}</TableCell>
                            </TableRow>
                        </TableHead>
                    {props.schedule.schedule.map((schedule: DaySchedule, index: number) => {
                        return <TableRow key={index}>
                            <TableCell>{schedule.days}</TableCell>
                            <TableCell>{schedule.working_hours}</TableCell>
                            <TableCell>{schedule.breaks}</TableCell>
                        </TableRow>
                    })}
                    </Table>
                    <Typography variant="body1">{props.schedule.text}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={event => {
                            setIsOpen(false);
                        }}
                    >
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};