import {types} from './types';
import axios from 'axios';
import {helpers} from "common/helpers";

const  buildUrl = require('build-url');

const login = (username: string, password: string) => (dispatch: any) => {
    let formData = new FormData();
    formData.set("username", username);
    formData.set("password", password);

    const url = buildUrl(process.env.REACT_APP_BACKEND_API_URL, {
        path: '/token/'
    });
    dispatch(loginProcess());
    return axios.post(url, formData)
        .then((response: any) => {
            localStorage.setItem('jwt_access_token', response.data.access);
            localStorage.setItem('jwt_refresh_token', response.data.refresh);
            dispatch(loginSuccess());
            return response.data;
        })
        .catch(function (error: any) {
            let errors = error.response.data;
            return Promise.reject(helpers.processErrors(errors));
        });

};

const loginProcess = () => ({
    type: types.AUTH_LOGIN_PROCESS
});

const loginSuccess = () => ({
    type: types.AUTH_LOGIN_SUCCESS,
    payload: {}
});

const logout = () => (dispatch: any) => {
    localStorage.clear();
    dispatch(logoutSuccess());
};

const logoutSuccess = () => ({
    type: types.AUTH_LOGOUT,
});

export const authActions = {
    login,
    logout
};