import * as React from 'react';
import {Field, Form} from "react-final-form";
import commonStyles from "common/styles";
import {makeStyles} from '@material-ui/styles';
import {Button, createStyles, Theme} from "@material-ui/core";
import 'react-sortable-tree/style.css';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import {FieldError, InfoBlock} from "common/components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import {TransitionProps} from "@material-ui/core/transitions";
import Toolbar from "@material-ui/core/Toolbar";
import {Contact, ContactCabinet, ContactMan, ContactPhone, ContactTypes} from "../models";
import cogoToast from 'cogo-toast';
import {helpers} from "common/helpers";
import {City} from "common/models";

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        color: "white",
        flex: 1,
    },
}));

interface AddContactPopupProps {
    city: City,
    onSave: (values: Contact[]) => any,
    handleClose: () => any
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const NewContactSet: React.FC<AddContactPopupProps> = (props: AddContactPopupProps) => {
    const classes = useStyles();

    const onSubmit = (values: any) => {
        let contacts: Contact[] = [];

        let positionLines: string[] = [];
        let fioLines: string[] = [];
        let phoneTitleLines: string[] = [];
        let phoneNumberLines: string[] = [];
        let cabinetTitleLines: string[] = [];
        let cabinetNumberLines: string[] = [];
        if (values.position && values.fio) {
            positionLines = values.position.split(/\r?\n/);
            fioLines = values.fio.split(/\r?\n/);
            if (positionLines.length !== fioLines.length) {
                cogoToast.error('В должностях и ФИО разное количество строк', {position: 'bottom-left'});
                return;
            }
        }

        if (values.phone_number) {
            phoneTitleLines = values.phone_title?values.phone_title.split(/\r?\n/):[];
            phoneNumberLines = values.phone_number.split(/\r?\n/);
        }

        if (values.cabinet_number) {
            cabinetTitleLines = values.cabinet_title?values.cabinet_title.split(/\r?\n/):[];
            cabinetNumberLines = values.cabinet_number.split(/\r?\n/);
        }

        for (let i = 0; i < positionLines.length; i++) {
            if (!fioLines[i] || !positionLines[i]) {
                continue;
            }

            const c: ContactMan = {
                type: ContactTypes.Man,
                position: positionLines[i],
                fio : fioLines[i],
                children: [],
            };

            if (phoneNumberLines[i]) {
                const title = phoneTitleLines[i]?phoneTitleLines[i]:"Телефон";

                let phone = phoneNumberLines[i];
                const formatedPhone = helpers.phoneFormatter(phone, props.city.code);
                if (formatedPhone) {
                    phone = formatedPhone;
                }

                // @ts-ignore
                c.children.push(
                    {
                        type: ContactTypes.Phone,
                        title : title,
                        number : phone
                    } as ContactPhone
                );
            }

            if (cabinetNumberLines[i]) {
                const title = cabinetTitleLines[i]?cabinetTitleLines[i]:"Кабинет";
                // @ts-ignore
                c.children.push(
                    {
                        type: ContactTypes.Cabinet,
                        title : title,
                        number : cabinetNumberLines[i]
                    } as ContactCabinet
                );
            }
            contacts.push(c)
        }

        for (let i = 0; i < phoneNumberLines.length; i++) {
            if (!positionLines[i] && phoneNumberLines[i]) {
                const title = phoneTitleLines[i]?phoneTitleLines[i]:"Телефон";

                let phone = phoneNumberLines[i];
                const formatedPhone = helpers.phoneFormatter(phone, props.city.code);
                if (formatedPhone) {
                    phone = formatedPhone;
                }

                contacts.push({
                    type: ContactTypes.Phone,
                    title : title,
                    number : phone
                } as ContactPhone)
            }
        }

        for (let i = 0; i < cabinetNumberLines.length; i++) {
            if (!positionLines[i] && cabinetNumberLines[i]) {
                const title = cabinetTitleLines[i]?cabinetTitleLines[i]:"Кабинет";
                contacts.push({
                    type: ContactTypes.Cabinet,
                    title : title,
                    number : cabinetNumberLines[i]
                } as ContactCabinet)
            }
        }


        /*if (values.position && values.fio) {
            const positions = values.position.split(/\r?\n/);
            const fios = values.fio.split(/\r?\n/);
            if (positions.length !== fios.length) {
                dispatch(notificationsActions.alert(NotificationTypes.Error, "В должностях и ФИО разное количество строк"));
                return;
            }

            for (let i = 0; i < positions.length; i++) {
                const c: ContactMan = {
                    type: ContactTypes.Man,
                    position: positions[i],
                    fio : fios[i]
                };
                contacts.push(c)
            }
        }

        if (values.phone_number) {
            const phoneTitles = values.phone_title?values.phone_title.split(/\r?\n/):[];
            const phoneNumbers = values.phone_number.split(/\r?\n/);

            for (let i = 0; i < phoneNumbers.length; i++) {
                const c: ContactPhone = {
                    type: ContactTypes.Phone,
                    title: phoneTitles[i]?phoneTitles[i]:"Телефон",
                    number : phoneNumbers[i]
                };
                contacts.push(c)
            }
        }

        if (values.cabinet_number) {
            const cabinetTitles = values.cabinet_title?values.cabinet_title.split(/\r?\n/):[];
            const cabinetNumbers = values.cabinet_number.split(/\r?\n/);

            for (let i = 0; i < cabinetNumbers.length; i++) {
                const c: ContactCabinet = {
                    type: ContactTypes.Cabinet,
                    title: cabinetTitles[i] ? cabinetTitles[i] : "Кабинет",
                    number: cabinetNumbers[i]
                };
                contacts.push(c)
            }
        }*/

        props.onSave(contacts);
    };

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, hasValidationErrors }) => {
                let submit: (values: any) => any = handleSubmit;
                return (
                    <form onSubmit={handleSubmit}>
                        <Dialog
                            open={true}
                            fullScreen
                            onClose={props.handleClose}
                            TransitionComponent={Transition}
                        >
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <Typography variant="h6" className={classes.title}>
                                        {"Добавление набора данных"}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <DialogContentText>
                                    Здесь можно добавить список людей с их телефонами и кабинетами, или отдельно - только людей, телефоны или кабинеты/судебные залы.
                                    Разбор данных идёт построчно. Если у кого-то из людей нет телефона или кабинета оставьте в соответствующем поле пустую строку напротив него.
                                </DialogContentText>
                                <Grid container spacing={1}>
                                    <Grid xs={12} md={6} item>
                                        <InfoBlock label={"Человек"}>
                                            <Grid container spacing={1}>
                                                <Grid xs={6} item>
                                                    <Field
                                                        name="position"
                                                        component="input"
                                                        type="text"
                                                    >
                                                        {({ input, meta, ...rest }) => (
                                                            <FormControl className={classes.formControl}>
                                                                <TextField
                                                                    label="Должность"
                                                                    fullWidth
                                                                    multiline
                                                                    rows="10"
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    {...input} {...rest}
                                                                    error={!!meta.error || !!meta.submitError}
                                                                />
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <FieldError text={meta.error || meta.submitError}/>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid xs={6} item>
                                                    <Field
                                                        name="fio"
                                                        component="input"
                                                        type="text"
                                                    >
                                                        {({ input, meta, ...rest }) => (
                                                            <FormControl className={classes.formControl}>
                                                                <TextField
                                                                    label="ФИО"
                                                                    fullWidth
                                                                    multiline
                                                                    rows="10"
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    {...input} {...rest}
                                                                    error={!!meta.error || !!meta.submitError}
                                                                />
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <FieldError text={meta.error || meta.submitError}/>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </InfoBlock>
                                    </Grid>
                                    <Grid xs={12} md={4} item>
                                        <InfoBlock label={"Телефон"}>
                                            <Grid container spacing={1}>
                                                <Grid xs={6} item>
                                                    <Field
                                                        name="phone_title"
                                                        component="input"
                                                        type="text"
                                                    >
                                                        {({ input, meta, ...rest }) => (
                                                            <FormControl className={classes.formControl}>
                                                                <TextField
                                                                    label="Заголовок телефона"
                                                                    multiline
                                                                    rows="10"
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    {...input} {...rest}
                                                                    error={!!meta.error || !!meta.submitError}
                                                                />
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <FieldError text={meta.error || meta.submitError}/>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid xs={6} item>
                                                    <Field
                                                        name="phone_number"
                                                        component="input"
                                                        type="text"
                                                    >
                                                        {({ input, meta, ...rest }) => (
                                                            <FormControl className={classes.formControl}>
                                                                <TextField
                                                                    label="Номер телефона"
                                                                    multiline
                                                                    rows="10"
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    {...input} {...rest}
                                                                    error={!!meta.error || !!meta.submitError}
                                                                />
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <FieldError text={meta.error || meta.submitError}/>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </InfoBlock>
                                    </Grid>
                                    <Grid xs={12} md={2} item>
                                        <InfoBlock label={"Кабинет"}>
                                            <Grid container spacing={1}>
                                                <Grid xs={6} item>
                                                    <Field
                                                        name="cabinet_title"
                                                        component="input"
                                                        type="text"
                                                    >
                                                        {({ input, meta, ...rest }) => (
                                                            <FormControl className={classes.formControl}>
                                                                <TextField
                                                                    label="Заголовок"
                                                                    multiline
                                                                    rows="10"
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    {...input} {...rest}
                                                                    error={!!meta.error || !!meta.submitError}
                                                                />
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <FieldError text={meta.error || meta.submitError}/>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid xs={6} item>
                                                    <Field
                                                        name="cabinet_number"
                                                        component="input"
                                                        type="text"
                                                    >
                                                        {({ input, meta, ...rest }) => (
                                                            <FormControl className={classes.formControl}>
                                                                <TextField
                                                                    label="Номер"
                                                                    multiline
                                                                    rows="10"
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    {...input} {...rest}
                                                                    error={!!meta.error || !!meta.submitError}
                                                                />
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <FieldError text={meta.error || meta.submitError}/>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </InfoBlock>
                                    </Grid>
                                </Grid>
                                <Typography>
                                    Поля с заголовками для телефона и кабинета можно оставить пустыми. В этом случае в них будет подставлено "Телефон" и "Кабинет".
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    disabled={hasValidationErrors}
                                    variant="contained"
                                    color="primary"
                                    onClick={event => {
                                        submit(event);
                                    }}
                                >
                                    Добавить
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={event => {
                                        props.handleClose();
                                    }}
                                >
                                    Отмена
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                )
        }}/>
    );
};