import React from 'react';
import {Choice, FieldError, SelectField, TextField} from "common/components";
import {Button} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {Form} from "react-final-form";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import createDecorator from 'final-form-calculate';
import {Post} from "../models";
import {helpers} from "common/helpers";
const slugify = require('slugify');

interface Props {
    title: string,
    posts: Post[],
    onClose: () => void,
    onSubmit: (values: Post) => any,
}

const calculator = createDecorator(
    {
        field: 'headline_short', // when minimum changes...
        updates: {
            // ...update maximum to the result of this function
            slug: (value, allValues) => value?slugify(value).toLowerCase():''
        }
    },
);

export const MetaDialog: React.FC<Props> = (props: Props) => {
    let submit: any;

    let allowedParents: Choice[] = helpers.postsTree(props.posts);

    return (
        <Dialog open={true}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <Form
                    onSubmit={props.onSubmit}
                    decorators={[calculator]}
                    render={({ handleSubmit, submitError }) => {
                        submit = handleSubmit;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <TextField
                                            label={"Название полностью"}
                                            name={"headline"}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label={"Название коротко"}
                                            name={"headline_short"}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SelectField
                                            name={"parent_id"}
                                            label={"Расположение"}
                                            choices={allowedParents}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label={"Слаг"}
                                            name={"slug"}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={"Заголовок страницы (Title)"}
                                            name={"title"}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={"Описание страницы (Description)"}
                                            name={"description"}
                                            multiline
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                {submitError && <FieldError text={submitError} />}
                            </form>
                        )
                    }}/>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={event => {
                        submit(event);
                    }}
                >
                    {"Добавить"}
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={props.onClose}
                >
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

