import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {match} from "react-router-dom";
import {courtsActions} from "../store/actions";
import {StateInterface} from "reducers";
import 'react-sortable-tree/style.css';
import * as H from "history";
import {Layout, Loadable} from "common/components";
import {CourtForm} from "./CourtForm";
import {Court} from "../models";

interface WithParams {
    area_id: number
    city_id: number
    court_id: number
}

interface RouteComponentProps<WithParams> {
    match: match<WithParams>;
    location: H.Location;
    history: H.History;
    staticContext?: any;
}

export const CourtEditPage = ({match}: RouteComponentProps<WithParams>) => {
    const dispatch = useDispatch();

    useEffect( () => {
        const {court_id} = match.params;
        dispatch(courtsActions.fetch(court_id));
    },[dispatch, match.params]);

    //const [isUpdated, setIsUpdated] = useState(false);

    const court = useSelector((state: StateInterface) => state.courts.court.data);
    //const courtsFilters = useSelector((state: StateInterface) => state.courts.courts.filters);
    const isLoading = useSelector((state: StateInterface) => state.courts.court.isLoading);

    useEffect( () => {
        window.scrollTo(0, 0);
    }, [court]);


    const onsubmit = async (court: Court) => {
        //setIsUpdated(true);
        try {
            await dispatch(courtsActions.update(court));
            // @ts-ignore
            //await dispatch(courtsActions.fetchAll(courtsFilters));
            //setIsUpdated(true);
        } catch (e) {
            console.dir(e);
            return e;
        }
    };
    // {isUpdated && <Redirect to={`/areas/${match.params.area_id}/cities/${match.params.city_id}/courts`}/>}
    return (
        <Layout pageName={court?court.name:''}>
            <Loadable isLoading={isLoading}>
                {court &&
                    <CourtForm
                        initial={court}
                        onSubmit={onsubmit}
                    />
                }
            </Loadable>
        </Layout>
    );
};

