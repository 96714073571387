require('./index.css').toString();

/**
 * @class Quote
 * @classdesc Quote Tool for Editor.js
 * @property {QuoteData} data - Tool`s input and output data
 * @propert {object} api - Editor.js API instance
 *
 * @typedef {object} QuoteData
 * @description Quote Tool`s input and output data
 * @property {string} text - quote`s text
 * @property {'center'|'left'} alignment - quote`s alignment
 *
 * @typedef {object} QuoteConfig
 * @description Quote Tool`s initial configuration
 * @property {string} quotePlaceholder - placeholder to show in quote`s text input
 * @property {'center'|'left'} defaultAlignment - alignment to use as default
 */
class MarkedText {
    /**
     * Get Tool toolbox settings
     * icon - Tool icon's SVG
     * title - title to show in toolbox
     *
     * @return {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            icon: '<svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg">' +
                '<path d="M16 6.707c0-3.139-0.919-5.687-2.054-5.707 0.005-0 0.009-0 0.014-0h-1.296c0 0-3.044 2.287-7.425 3.184-0.134 0.708-0.219 1.551-0.219 2.523s0.085 1.816 0.219 2.523c4.382 0.897 7.425 3.184 7.425 3.184h1.296c-0.005 0-0.009-0-0.014-0.001 1.136-0.020 2.054-2.567 2.054-5.707zM13.513 11.551c-0.147 0-0.305-0.152-0.387-0.243-0.197-0.22-0.387-0.562-0.55-0.989-0.363-0.957-0.564-2.239-0.564-3.611s0.2-2.655 0.564-3.611c0.162-0.428 0.353-0.77 0.55-0.99 0.081-0.091 0.24-0.243 0.387-0.243s0.305 0.152 0.387 0.243c0.197 0.22 0.387 0.562 0.55 0.99 0.363 0.957 0.564 2.239 0.564 3.611s-0.2 2.655-0.564 3.611c-0.162 0.428-0.353 0.77-0.55 0.989-0.081 0.091-0.24 0.243-0.387 0.243zM3.935 6.707c0-0.812 0.060-1.6 0.173-2.33-0.74 0.102-1.39 0.161-2.193 0.161-1.048 0-1.048 0-1.048 0l-0.867 1.479v1.378l0.867 1.479c0 0 0 0 1.048 0 0.803 0 1.453 0.059 2.193 0.161-0.113-0.729-0.173-1.518-0.173-2.33zM5.752 10.034l-2-0.383 1.279 5.024c0.066 0.26 0.324 0.391 0.573 0.291l1.852-0.741c0.249-0.1 0.349-0.374 0.222-0.611l-1.926-3.581zM13.513 8.574c-0.057 0-0.118-0.059-0.149-0.094-0.076-0.085-0.149-0.217-0.212-0.381-0.14-0.369-0.217-0.863-0.217-1.392s0.077-1.023 0.217-1.392c0.063-0.165 0.136-0.297 0.212-0.381 0.031-0.035 0.092-0.094 0.149-0.094s0.118 0.059 0.149 0.094c0.076 0.085 0.149 0.217 0.212 0.381 0.14 0.369 0.217 0.863 0.217 1.392s-0.077 1.023-0.217 1.392c-0.063 0.165-0.136 0.297-0.212 0.381-0.031 0.035-0.092 0.094-0.149 0.094z"/>' +
                '</svg>',
            title: 'Выделенный текст'
        };
    }

    /**
     * Empty Quote is not empty Block
     * @public
     * @returns {boolean}
     */
    static get contentless() {
        return true;
    }

    /**
     * Allow to press Enter inside the Quote
     * @public
     * @returns {boolean}
     */
    static get enableLineBreaks() {
        return true;
    }

    /**
     * Default placeholder for quote text
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_QUOTE_PLACEHOLDER() {
        return 'Текст';
    }

    /**
     * Allowed quote alignments
     *
     * @public
     * @returns {{left: string, center: string}}
     */
    static get ALIGNMENTS() {
        return {
            left: 'left',
            center: 'center'
        };
    }

    /**
     * Default quote alignment
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_ALIGNMENT() {
        return MarkedText.ALIGNMENTS.left;
    }

    /**
     * Allow Quote to be converted to/from other blocks
     */
    static get conversionConfig(){
        return {
            /**
             * To create Quote data from string, simple fill 'text' property
             */
            import: 'text',
            /**
             * @param {QuoteData} quoteData
             * @return {string}
             */
            export: function (quoteData) {
                return quoteData.text;
            }
        };
    }

    /**
     * Tool`s styles
     *
     * @returns {{baseClass: string, wrapper: string, quote: string, input: string, settingsButton: string, settingsButtonActive: string}}
     */
    get CSS() {
        return {
            baseClass: this.api.styles.block,
            wrapper: 'cdx-quote',
            text: 'cdx-quote__text',
            input: this.api.styles.input,
            settingsWrapper: 'cdx-quote-settings',
            settingsButton: this.api.styles.settingsButton,
            settingsButtonActive: this.api.styles.settingsButtonActive,
            variantSelector: 'cdx-quote__variant-selector'
        };
    }

    /**
     * Tool`s settings properties
     *
     * @returns {*[]}
     */
    get settings() {
        return [
            {
                name: 'left',
                icon: `<svg width="16" height="11" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg" ><path d="M1.069 0H13.33a1.069 1.069 0 0 1 0 2.138H1.07a1.069 1.069 0 1 1 0-2.138zm0 4.275H9.03a1.069 1.069 0 1 1 0 2.137H1.07a1.069 1.069 0 1 1 0-2.137zm0 4.275h9.812a1.069 1.069 0 0 1 0 2.137H1.07a1.069 1.069 0 0 1 0-2.137z" /></svg>`
            },
            {
                name: 'center',
                icon: `<svg width="16" height="11" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg" ><path d="M1.069 0H13.33a1.069 1.069 0 0 1 0 2.138H1.07a1.069 1.069 0 1 1 0-2.138zm3.15 4.275h5.962a1.069 1.069 0 0 1 0 2.137H4.22a1.069 1.069 0 1 1 0-2.137zM1.069 8.55H13.33a1.069 1.069 0 0 1 0 2.137H1.07a1.069 1.069 0 0 1 0-2.137z"/></svg>`
            }
        ];
    }

    /**
     * Render plugin`s main Element and fill it with saved data
     *
     * @param {{data: QuoteData, config: QuoteConfig, api: object}}
     *   data — previously saved data
     *   config - user config for Tool
     *   api - Editor.js API
     */
    constructor({data, config, api}) {
        const {ALIGNMENTS, DEFAULT_ALIGNMENT} = MarkedText;

        this.api = api;

        this.quotePlaceholder = config.quotePlaceholder || MarkedText.DEFAULT_QUOTE_PLACEHOLDER;

        this.data = {
            text: data.text || '',
            variant: data.variant || '1',
            alignment: (Object.values(ALIGNMENTS).includes(data.alignment) && data.alignment) ||
                config.defaultAlignment ||
                DEFAULT_ALIGNMENT
        };
    }

    /**
     * Create Quote Tool container with inputs
     *
     * @returns {Element}
     */
    render() {
        const container = this._make('blockquote', [this.CSS.baseClass, this.CSS.wrapper]);
        const quote = this._make('div', [this.CSS.input, this.CSS.text], {
            contentEditable: true,
            innerHTML: this.data.text
        });

        let variant = this._make('select', [this.CSS.input, this.CSS.variantSelector]);

        for (let i = 1; i <= 2; i++) {
            const option = document.createElement("option");
            option.value = String(i);
            option.text = "Вариант " + String(i);
            variant.append(option);
        }

        variant.value = this.data.variant;

        let me = this;
        variant.onchange = () => {
            me.data =  Object.assign(me.data, {
                variant: variant.value
            });
        };

        quote.dataset.placeholder = this.quotePlaceholder;

        container.appendChild(quote);
        container.appendChild(variant);

        return container;
    }

    /**
     * Extract Quote data from Quote Tool element
     *
     * @param {HTMLDivElement} quoteElement - element to save
     * @returns {QuoteData}
     */
    save(quoteElement) {
        const text = quoteElement.querySelector(`.${this.CSS.text}`);
        const variant = quoteElement.querySelector(`.${this.CSS.variantSelector}`);

        return Object.assign(this.data, {
            text: text.innerHTML,
            variant: variant.value
        });
    }

    /**
     * Sanitizer rules
     */
    static get sanitize() {
        return {
            text: {
                br: true,
            },
            alignment: {}
        };
    }

    /**
     * Create wrapper for Tool`s settings buttons:
     * 1. Left alignment
     * 2. Center alignment
     *
     * @returns {HTMLDivElement}
     */
    renderSettings() {
        const wrapper = this._make('div', [ this.CSS.settingsWrapper ], {});
        const capitalize = str => str[0].toUpperCase() + str.substr(1);

        this.settings
            .map( tune => {
                const el = this._make('div', this.CSS.settingsButton, {
                    innerHTML: tune.icon,
                    title: `${capitalize(tune.name)} alignment`
                });

                el.classList.toggle(this.CSS.settingsButtonActive, tune.name === this.data.alignment);

                wrapper.appendChild(el);

                return el;
            })
            .forEach((element, index, elements) => {
                element.addEventListener('click', () => {
                    this._toggleTune(this.settings[index].name);

                    elements.forEach((el, i) => {
                        const {name} = this.settings[i];

                        el.classList.toggle(this.CSS.settingsButtonActive, name === this.data.alignment);
                    });
                });
            });

        return wrapper;
    };

    /**
     * Toggle quote`s alignment
     *
     * @param {string} tune - alignment
     * @private
     */
    _toggleTune(tune) {
        this.data.alignment = tune;
    }

    /**
     * Helper for making Elements with attributes
     *
     * @param  {string} tagName           - new Element tag name
     * @param  {array|string} classNames  - list or name of CSS classname(s)
     * @param  {Object} attributes        - any attributes
     * @return {Element}
     */
    _make(tagName, classNames = null, attributes = {}) {
        let el = document.createElement(tagName);

        if ( Array.isArray(classNames) ) {
            el.classList.add(...classNames);
        } else if( classNames ) {
            el.classList.add(classNames);
        }

        for (let attrName in attributes) {
            el[attrName] = attributes[attrName];
        }

        return el;
    }
}

export default MarkedText;