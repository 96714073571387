import * as React from 'react';
import commonStyles from "common/styles";
import {makeStyles} from '@material-ui/styles';
import {AppBar, Box, Button, createStyles, Dialog, DialogTitle, Tabs, Theme} from "@material-ui/core";
import 'react-sortable-tree/style.css';
// @ts-ignore
import {removeNodeAtPath, SortableTreeWithoutDndContext as SortableTree} from 'react-sortable-tree';
import {externalNodeType} from "./ContactRenderResolver";
import {ContactTab} from "./ContactTab";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {ContactNodeRenderer} from "./ContactNodeRenderer";
import Typography from "@material-ui/core/Typography";
import {ContactsTab} from "../models";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from '@material-ui/core/InputLabel';
import {City} from "common/models";

const classNames = require('classnames');

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    treeFormControl: {
        paddingTop: "0.5rem"
    },
    actions: {
        marginBottom: "0.5rem"
    }
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

interface Props {
    city: City;
    onChange: (data: any) => any,
    value: ContactsTab[],
    externalNodeType: string
}

export const ContactTree: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const onChange = (tabIndex: number, data: any) => {
        let newValue = [...props.value];
        newValue[tabIndex].contacts = data;
        props.onChange(newValue);
    };

    const getNodeKey = (prop: any) => prop.treeIndex;

    const a11yProps = (index: any) => {
        return {
            id: `scrollable-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    };

    const [activeTab, setActiveTab] = React.useState(0);
    const [deletingTab, setDeletingTab] = React.useState<ContactsTab | null>(null);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    const removeTab = (tabToRemove: ContactsTab) => {
        let newTabs: ContactsTab[] = [];
        let activeIndexAfterDelete = 0;
        props.value.forEach((tab: ContactsTab, index: number) => {
            if (tab !== tabToRemove) {
                newTabs.push(tab);
            } else {
                if (index !== 0) {
                    activeIndexAfterDelete = index - 1;
                }
            }
        });
        setDeletingTab(null);
        setActiveTab(activeIndexAfterDelete);
        props.onChange(newTabs);
    };

    const changeTabName = (event: any, tab: ContactsTab) => {
        let newValue = [...props.value];
        newValue.forEach((t: ContactsTab, index: number) => {
            if (tab === t) {
                newValue[index].name = event.target.value
            }
        });
        props.onChange(newValue);
    };

    const changeTabTitle = (event: any, tab: ContactsTab) => {
        let newValue = [...props.value];
        newValue.forEach((t: ContactsTab, index: number) => {
            if (tab === t) {
                newValue[index].title = event.target.value
            }
        });
        props.onChange(newValue);
    };

    const changeTabDescription = (event: any, tab: ContactsTab) => {
        let newValue = [...props.value];
        newValue.forEach((t: ContactsTab, index: number) => {
            if (tab === t) {
                newValue[index].text = event.target.value
            }
        });
        props.onChange(newValue);
    };

    const handleCreateTab = () => {
        let newValue = [...props.value];
        const activeTabIndex = newValue.length;
        newValue.push({
            name: `Новый таб ${activeTabIndex}`,
            title: "",
            text: "",
            contacts: []
        } as ContactsTab);
        setActiveTab(activeTabIndex);
        props.onChange(newValue);
    };

    return (
        <>
            <div className={classes.actions}>
                <Button
                    size={"small"}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleCreateTab}
                >
                    Создать таб
                </Button>
            </div>
            <AppBar position="static" color="default">
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {props.value.map((tab: ContactsTab, tabIndex: number) => {
                        return <ContactTab
                            key={tabIndex}
                            label={<>
                                {tab.name}
                                <IconButton
                                    size={"small"}
                                    color={"secondary"}
                                    aria-label="delete"
                                    onClick={() => setDeletingTab(tab)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </>
                            }
                            {...a11yProps(tabIndex)}
                        />
                    })}
                </Tabs>
            </AppBar>
            {props.value.map((tab: ContactsTab, tabIndex: number) => {
                const index = tabIndex;

                return <TabPanel value={activeTab} index={index} key={`contact-tab-${index}`}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Название"
                            value={tab.name}
                            onChange={(ev: any) => changeTabName(ev, tab)}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Заголовок"
                            value={tab.title}
                            onChange={(ev: any) => changeTabTitle(ev, tab)}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label="Текст"
                            multiline={true}
                            variant="outlined"
                            value={tab.text}
                            onChange={(ev: any) => changeTabDescription(ev, tab)}
                        />
                    </FormControl>
                    <FormControl className={classNames(classes.formControl, classes.treeFormControl)}>
                        <InputLabel shrink>Контакты</InputLabel>
                        <SortableTree
                            isVirtualized={false}
                            treeData={tab.contacts}
                            dndType={externalNodeType}
                            onChange={(treeData: any) => onChange(index, treeData)}
                            nodeContentRenderer={ContactNodeRenderer}
                            generateNodeProps={(prop: any) => ({
                                city: props.city,
                                buttons: [
                                    <IconButton
                                        size={"small"}
                                        color={"secondary"}
                                        aria-label="delete"
                                        onClick={() => onChange(index, removeNodeAtPath({
                                            treeData: tab.contacts,
                                            path: prop.path,
                                            getNodeKey,
                                        }))}>
                                        <DeleteIcon/>
                                    </IconButton>,
                                ],
                            })}
                        />
                    </FormControl>
                </TabPanel>
            })}

            {deletingTab &&
            <Dialog
                open={true}
                onClose={() => setDeletingTab(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Удалить вкладку?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите удалить вкладку <b>{deletingTab.name}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => removeTab(deletingTab)} color="primary">
                        Да
                    </Button>
                    <Button onClick={() => setDeletingTab(null)} color="secondary" autoFocus>
                        Нет
                    </Button>
                </DialogActions>
            </Dialog>
            }
        </>
    );
};