import axios, {AxiosRequestConfig} from 'axios';
import {store} from "../store";
import {authActions} from "../users/store/actions";
import cogoToast from "cogo-toast";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL
});

apiClient.interceptors.request.use(function (config: AxiosRequestConfig) {
    const cleanJSON = (json: any) => {
        // Потому что axios undefined-значения выпиливает, и надо заменять на null
        for (let key in json) {
            if (json[key] === undefined) {
                json[key] = null;
            } else if (typeof json[key] === 'object') {
                json[key] = cleanJSON(json[key]);
            }
        }
        return json;
    };

    if (['post', 'put', 'patch'].includes(config.method as string)) {
        config.data = cleanJSON(config.data);
    }
    return config;
},function (error: any) {
    // Do something with request error
    return Promise.reject(error);
});

// К каждому запросу добавляет заголовок авторизации
apiClient.interceptors.request.use(function (config: AxiosRequestConfig) {
    if (localStorage.getItem("jwt_access_token") !== null) {
        config.headers.Authorization = 'Bearer ' +  localStorage.getItem("jwt_access_token");
    }
    return config;
},function (error: any) {
    // Do something with request error
    return Promise.reject(error);
});

// Обработка ошибок
apiClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const originalRequest = error.config;

    if (!error.response || error.response.status === 500 || (error.response.config.method === 'get' && error.response.status === 400)) {
        cogoToast.error('Что-то пошло не так...', {position: 'bottom-left'});
        return Promise.reject(error);
    }

    if (error.response.status === 401) {
        if (localStorage.getItem("jwt_refresh_token") !== null && !originalRequest._retry) {
            originalRequest._retry = true;
            let formData = new FormData();
            formData.set("refresh", localStorage.getItem('jwt_refresh_token') as string);
            return axios.post(process.env.REACT_APP_BACKEND_API_URL + '/token/refresh/', formData)
                .then(function (response) {
                    const { access } = response.data;
                    localStorage.setItem('jwt_access_token', access);
                    return apiClient.request(originalRequest)
                })
                .catch(function (error) {
                    if (error.response && error.response.status === 401) {
                        localStorage.clear();
                        store.dispatch(authActions.logout() as any);
                    }
                    return Promise.reject(error)
                });
        } else {
            localStorage.clear();
            store.dispatch(authActions.logout() as any);
        }
    }
    return Promise.reject(error);
});

export const ApiClient = apiClient;