import * as React from 'react';
import {createStyles, FormLabel, Theme} from "@material-ui/core";
import {makeStyles} from '@material-ui/styles';
import commonStyles from "common/styles";
import FormControl from '@material-ui/core/FormControl';
import {FieldError} from "./FieldError";
import {Field} from "react-final-form";
import {validators} from "../validators";
import Editor from "./Editor/Editor";
import CustomQuote from "./Editor/CustomQuote";
import TitleAndText from "./Editor/TitleAndText";
import IconAndText from "./Editor/IconAndText";
import MarkedText from "./Editor/MarkedText";
import IconAndLink from "./Editor/IconAndLink";
import CallBlock from "./Editor/CallBlock";
import Attachment from "./Editor/Attachment";
import {filesActions} from "common/store/actions";
const Paragraph = require('@editorjs/paragraph');

const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    editor: {
        marginTop: '0.3rem',
        border: '1px solid rgba(0, 0, 0, 0.22)',
        borderRadius: '0.1rem'
    }
}));

interface Props {
    name: string
    label: string
    required?: boolean
}

export const BlockTextField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name, label} = props;

    const required = !!props.required;

    let validatorsList: any = [];
    if (required) {
        validatorsList.push(validators.notEmptyEditorJS);
    }

    return (
        <Field
            name={name}
            component="input"
            type="text"
            validate={validators.composeValidators(...validatorsList)}
            required={required}
        >
            {({input, meta, ...rest}) => {
                const error = meta.error || meta.submitError;
                let value: any = input.value;

                // @ts-ignore
                const timestamp = Math.round(new Date() / 1000);
                if (!value) {
                    value = {
                        "time": timestamp,
                        "blocks": [],
                        "version": "2.18.0"
                    };
                }
                return <FormControl className={classes.formControl}>
                    <FormLabel
                        component="legend"
                        error={meta.touched && error}
                        required={required}
                    >{label}</FormLabel>

                    <div className={classes.editor}>
                        <Editor
                            holder={"editorjs"}
                            onChange={input.onChange}
                            customTools={
                                {
                                    markedText: {
                                        class: MarkedText,
                                        inlineToolbar: true,
                                    },
                                    customQuote: {
                                        class: CustomQuote,
                                        inlineToolbar: true,
                                    },
                                    titleAndText: {
                                        class: TitleAndText,
                                        inlineToolbar: true,
                                    },
                                    iconAndLink: {
                                        class: IconAndLink,
                                        inlineToolbar: true,
                                        config: {
                                            uploader: {
                                                uploadByFile(file: File) {
                                                    return filesActions.uploadImageFromEditor(file);
                                                }
                                            }
                                        }
                                    },
                                    iconAndText: {
                                        class: IconAndText,
                                        inlineToolbar: true,
                                        config: {
                                            uploader: {
                                                uploadByFile(file: File) {
                                                    return filesActions.uploadImageFromEditor(file);
                                                }
                                            }
                                        }
                                    },
                                    paragraph: {
                                        class: Paragraph,
                                        inlineToolbar : true
                                    },
                                    call: {
                                        class: CallBlock,
                                        inlineToolbar: true,
                                    },
                                    attachment: {
                                        class: Attachment,
                                        inlineToolbar: true,
                                        config: {
                                            uploader: {
                                                uploadByFile(file: File) {
                                                    return filesActions.uploadFromEditor(file);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            excludeDefaultTools={['quote','checklist','code','raw']}
                            onBlur={() => {
                                // @ts-ignore
                                return input.onBlur(document.createEvent('HTMLEvents'));
                            }}
                            data={value}
                        />
                    </div>
                    {error &&
                    <FieldError text={error}/>
                    }
                </FormControl>
            }}
        </Field>

    );
};

