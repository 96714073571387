import {FORM_ERROR} from "final-form";
import {Post} from "../../posts/models";
import {Choice} from "../components";

export enum NotificationTypes {
    Error = 'error',
    Info = 'info',
    Success = 'success',
    Warning = 'warning'
}


const processErrors = (errors: any) => {
    return Object.keys(errors).reduce(function (processed: any, key) {
        processed[key === 'non_field_errors' || key === 'details' || key === 'detail' ? FORM_ERROR : key] = (Array.isArray(errors[key]) ? errors[key][0] : errors[key]) as string;
        return processed;
    }, {});
};

const phoneFormatter = (phone: string, cityCode: string) => {
    if (phone.startsWith("+7")) {
        return phone;
    }
    cityCode = String(cityCode);
    const isCityNumber = (phoneNumber: string, code: string) => {
        // @ts-ignore
        let clearNum = phoneNumber.match(/\d/g).join("");
        if (clearNum.length === 10 - code.length) {
            return true;
        }

        clearNum = clearNum.substring(clearNum.length - 10);
        if (clearNum[0] === "9") {
            return false;
        }

        if (clearNum.startsWith(code)) {
            return true;
        }

        return undefined;
    };

    // @ts-ignore
    let onlyNum = phone.match(/\d/g).join("");
    const isCityNum = isCityNumber(onlyNum, cityCode);
    if (isCityNum === undefined) {
        return false;
    }
    if (isCityNum) {
        if (onlyNum.length === 10) {
            onlyNum = onlyNum.substring(String(cityCode).length);
        } else if (onlyNum.length === 11) {
            onlyNum = onlyNum.substring(String(cityCode).length + 1);
        }

        switch (String(cityCode).length) {
            case 3:
                return '+7 ' + cityCode + ' ' + onlyNum.substring(0, 3) + '-' + onlyNum.substring(3, 5) + '-' + onlyNum.substring(5, 7);
            case 4:
                return '+7 ' + cityCode + ' ' + onlyNum.substring(0, 2) + '-' + onlyNum.substring(2, 4) + '-' + onlyNum.substring(4, 6);
            case 5:
                return '+7 ' + cityCode + ' ' + onlyNum.substring(0, 1) + '-' + onlyNum.substring(1, 3) + '-' + onlyNum.substring(3, 5);
        }
    } else {
        if (onlyNum.length === 11) {
            onlyNum = onlyNum.substring(1);
        }
        return '+7 ' + onlyNum.substring(0, 3) + ' ' + onlyNum.substring(3, 6) + '-' + onlyNum.substring(6, 8) + '-' + onlyNum.substring(8, 10);
    }
    return false;
};

const postsTree = (posts: Post[], level: number = 0, items: Choice[] = []) => {
    for (let i = 0; i < posts.length; i++) {
        let space = '\u00A0\u00A0\u00A0'.repeat(level);
        items.push({
            key: posts[i].id as number,
            value: space + posts[i].headline as string,
        });
        //postsTree(posts[i].children as Post[], level + 1, items)
    }
    return items;
};

export const helpers = {
    processErrors,
    postsTree,
    phoneFormatter
};