import * as React from 'react';
import {Field, Form} from "react-final-form";
import {Button} from "@material-ui/core";
import {Contact, Court} from "../models";
import Divider from "@material-ui/core/Divider";
import 'react-sortable-tree/style.css';
import Grid from "@material-ui/core/Grid";
import {externalNodeType} from "./ContactRenderResolver";
import {ContactTree} from "./ContactTree";
import {Choice, FormBlock, SelectField, TreeField} from "common/components";
import {makeStyles} from "@material-ui/styles";
import styles from "common/styles/styles";
import {courtsActions} from "../store/actions";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {useDispatch} from "react-redux";


interface CourtFormProps {
    initial?: Court,
    onSubmit: (values: any) => any,
}

interface TreeNode {
    contact: Contact;
    title: string;
    subtitle?: string;
    expanded?: boolean;
    children?: TreeNode[];
}

const useStyles = makeStyles({
    link: {
        ...styles.link,
        marginLeft: '1rem'
    },
    draftWidget: {
        padding: '0.5rem',
        border: `1px solid ${styles.grayColor}`,
        borderRadius: '3px'
    },
    draftTitle: {
        marginTop: 0
    }
});

export const CourtForm: React.FC<CourtFormProps> = (props: CourtFormProps) => {
    let submit: (values: any) => any;

    const classes = useStyles();
    const dispatch = useDispatch();

    let initial: any = props.initial;
    if (initial && !initial.contacts_reception) {
        initial.contacts_reception = [
            {
                "name": "Приёмная",
                "title": "Приёмная {{суда_полностью}}",
                "contacts": [],
            },
            {
                "name": "Гражданские дела",
                "title": "Отдел по гражданским делам",
                "contacts": [],
            },
            {
                "name": "Уголовные дела",
                "title": "Отдел по уголовным делам",
                "contacts": [],
            },
            {
                "name": "Административные дела",
                "title": "Отдел по административным делам",
                "contacts": [],
            },
            {
                "name": "Архив",
                "title": "Архив суда",
                "contacts": [],
            },
        ];
    }
    if (initial && !initial.contacts_judges) {
        initial.contacts_judges = [{
            "name": "Председатель",
            "title": "Председатель {{суда_коротко}}",
            "contacts": [],
        },
        {
            "name": "Судьи",
            "title": "Судьи {{суда_полностью}}",
            "contacts": [],
        },];
    }

    const savePreview = async (court: Court) => {
        // @ts-ignore
        const preview: Court = await dispatch(courtsActions.savePreview(court));
        window.open(`${process.env.REACT_APP_DOMAIN}/previews/${initial.city.slug}/courts/${preview.preview_token}/`, '_blank');
    };

    return (
        <Form
            onSubmit={props.onSubmit}
            initialValues={initial}
            render={({ handleSubmit, values, form }) => {
                submit = handleSubmit;

                return (
                    <>
                    <form onSubmit={handleSubmit}>
                        <FormBlock label={"Основные контакты"}>
                            <TreeField
                                label={"Контакты"}
                                city={initial.city}
                                site={initial.site}
                                name={"contacts_main"}
                                externalNodeType={externalNodeType}/>
                        </FormBlock>
                        <Divider/>
                        <FormBlock label={"Отделы суда"}>
                            <Field
                                name="contacts_reception"
                                component="input"
                                type="text"
                            >
                                {({ input, meta, ...rest }) => (
                                    <ContactTree
                                        city={initial.city}
                                        onChange={input.onChange}
                                        value={input.value}
                                        externalNodeType={externalNodeType}
                                    />
                                )}
                            </Field>
                        </FormBlock>
                        <Divider/>
                        <FormBlock label={"Судьи и аппарат суда"}>
                            <Field
                                name="contacts_judges"
                                component="input"
                                type="text"
                            >
                                {({ input, meta, ...rest }) => (
                                    <ContactTree
                                        city={initial.city}
                                        onChange={input.onChange}
                                        value={input.value}
                                        externalNodeType={externalNodeType}
                                    />
                                )}
                            </Field>
                        </FormBlock>
                    </form>

                    <Divider/>
                        <>
                            <h2 className={classes.draftTitle}>Черновик</h2>
                            <div className={classes.draftWidget}>
                                <TreeField
                                    label={"Люди и отделы"}
                                    city={initial.city}
                                    site={initial.site}
                                    name={"draft_contacts"}
                                    allowCreate={true}
                                    externalNodeType={externalNodeType}
                                    allowDelete
                                />
                            </div>
                        </>
                        <br/>
                        <Grid container spacing={1}>
                            <Grid xs={4}>
                                <SelectField
                                    name={"status"}
                                    label={"Статус"}
                                    choices={[
                                        {
                                            key: 'publish',
                                            value: 'Опубликовано'
                                        },
                                        {
                                            key: 'draft',
                                            value: 'Черновик'
                                        },
                                    ] as Choice[]}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <br/>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={event => {
                                submit(event);
                            }}
                        >
                            Сохранить
                        </Button>
                        {initial && initial.site && <a
                            className={classes.link}
                            rel="noopener noreferrer"
                            target={"_blank"}
                            href={initial.site.startsWith('http')?initial.site : ("//" + initial.site)}>{initial.site}</a>}
                        <Button
                            size={"small"}
                            onClick={event => {
                                savePreview(form.getState().values);
                            }}
                            color={"primary"}>
                            <VisibilityIcon/>
                        </Button>
                    </>
                )
            }
        }/>
    );
};