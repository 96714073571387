import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "@material-ui/core/CardHeader";

export const ContactCardHeader = withStyles({
    root: {
        padding: "2px",
        height: "100%"
    },
    title: {
        fontSize: "0.7rem"
    },
    subheader: {
        fontSize: "0.7rem"
    },
    action: {
        marginRight: 0,
        alignSelf: 'unset'
    },
    avatar: {
        marginRight: "4px"
    }
})(CardHeader);