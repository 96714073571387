import React, {Component} from 'react';
import {ContactCardHeader} from "./ContactCardHeader";
import {ContactCard} from "./ContactCard";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import HomeIcon from '@material-ui/icons/Home';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import GroupIcon from '@material-ui/icons/Group';
import FaceIcon from '@material-ui/icons/Face';
import ContactSiteIcon from '@material-ui/icons/Link';
import ScheduleIcon from '@material-ui/icons/Schedule';
import {ContactTypes} from "../models";
import {PhoneBlock} from "./PhoneBlock";
import {City} from "common/models";
import {ScheduleTitle} from "./ScheduleTitle";

const classNames = require('classnames');

interface NodeRenderer {
    node: any,
    title: any,
    subtitle: any,
    path: any,
    treeIndex: number,
    treeId: string,
    isSearchMatch: boolean,
    isSearchFocus: boolean,
    canDrag: boolean,
    scaffoldBlockPxWidth: number,
    toggleChildrenVisibility: any,
    buttons: any,
    className: string,
    style: any,
    connectDragPreview: any,
    connectDragSource: any,
    parentNode: any,
    isDragging: boolean,
    didDrop: boolean,
    draggedNode: any,
    // Drop target
    isOver: boolean,
    canDrop: boolean,
    rowDirection: string,
    city: City;
}

export class ContactNodeRenderer extends Component<NodeRenderer> {
    render() {
        const isDescendant = (older: any, younger: any) => {
            return (
                !!older.children &&
                typeof older.children !== 'function' &&
                older.children.some(
                    (child: any) => child === younger || isDescendant(child, younger)
                )
            );
        };

        const {
            scaffoldBlockPxWidth,
            toggleChildrenVisibility,
            connectDragPreview,
            connectDragSource,
            isDragging,
            canDrop,
            canDrag,
            node,
            title,
            subtitle,
            draggedNode,
            path,
            treeIndex,
            isSearchMatch,
            isSearchFocus,
            buttons,
            className,
            style,
            didDrop,
            treeId,
            isOver, // Not needed, but preserved for other renderers
            parentNode, // Needed for dndManager
            rowDirection,
            city,
            ...otherProps
        } = this.props;

        const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;

        let handle;
        if (canDrag) {
            if (typeof node.children === 'function' && node.expanded) {
                // Show a loading symbol on the handle when the children are expanded
                //  and yet still defined by a function (a callback to fetch the children)
                handle = (
                    <div className="rst__loadingHandle">
                        <div className="rst__loadingCircle">
                            {[...new Array(12)].map((_, index) => (
                                <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className={classNames(
                                        'rst__loadingCirclePoint',
                                        rowDirectionClass
                                    )}
                                />
                            ))}
                        </div>
                    </div>
                );
            } else {
                // Show the handle used to initiate a drag-and-drop
                handle = connectDragSource(<div className="rst__moveHandle"/>, {
                    dropEffect: 'copy',
                });
            }
        }

        const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
        const isLandingPadActive = !didDrop && isDragging;

        let buttonStyle = {left: -0.5 * scaffoldBlockPxWidth};
        if (rowDirection === 'rtl') {
            // @ts-ignore
            buttonStyle = {right: -0.5 * scaffoldBlockPxWidth};
        }

        return (
            <div style={{height: '100%'}} {...otherProps}>
                {toggleChildrenVisibility && node.children && (node.children.length > 0 || typeof node.children === 'function') && (
                    <div>
                        <button
                            type="button"
                            aria-label={node.expanded ? 'Collapse' : 'Expand'}
                            className={classNames(
                                node.expanded ? 'rst__collapseButton' : 'rst__expandButton',
                                rowDirectionClass
                            )}
                            style={buttonStyle}
                            onClick={() =>
                                toggleChildrenVisibility({
                                    node,
                                    path,
                                    treeIndex,
                                })
                            }
                        />

                        {node.expanded && !isDragging && (
                            <div
                                style={{width: scaffoldBlockPxWidth}}
                                className={classNames('rst__lineChildren', rowDirectionClass)}
                            />
                        )}
                    </div>
                )}

                <div className={classNames('rst__rowWrapper', rowDirectionClass)}>
                    {node.contact && connectDragPreview(
                        <div
                            className={classNames(
                                'rst__row',
                                isLandingPadActive && 'rst__rowLandingPad',
                                isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                                isSearchMatch && 'rst__rowSearchMatch',
                                isSearchFocus && 'rst__rowSearchFocus',
                                rowDirectionClass,
                                className
                            )}
                            style={{
                                opacity: isDraggedDescendant ? 0.5 : 1,
                                ...style,
                            }}
                        >
                            {handle}
                            {node.contact.type === ContactTypes.Address &&
                            <ContactCard>
                                <ContactCardHeader
                                    avatar={
                                        <HomeIcon/>
                                    }
                                    title={node.contact.title}
                                    subheader={node.contact.address}
                                    action={buttons && buttons[0]}
                                />
                            </ContactCard>
                            }
                            {node.contact.type === ContactTypes.Cabinet &&
                            <ContactCard>
                                <ContactCardHeader
                                    avatar={
                                        <VpnKeyIcon/>
                                    }
                                    title={node.contact.title}
                                    subheader={node.contact.number}
                                    action={buttons && buttons[0]}
                                />
                            </ContactCard>
                            }
                            {node.contact.type === ContactTypes.Department &&
                            <ContactCard>
                                <ContactCardHeader
                                    avatar={
                                        <GroupIcon/>
                                    }
                                    title={node.contact.title}
                                    subheader={node.contact.name}
                                    action={buttons && buttons[0]}
                                />
                            </ContactCard>
                            }
                            {node.contact.type === ContactTypes.Email &&
                            <ContactCard>
                                <ContactCardHeader
                                    avatar={
                                        <ContactMailIcon/>
                                    }
                                    title={node.contact.title}
                                    subheader={node.contact.email}
                                    action={buttons && buttons[0]}
                                />
                            </ContactCard>
                            }
                            {node.contact.type === ContactTypes.Site &&
                            <ContactCard>
                                <ContactCardHeader
                                    avatar={
                                        <ContactSiteIcon/>
                                    }
                                    title={node.contact.title}
                                    subheader={node.contact.site}
                                    action={buttons && buttons[0]}
                                />
                            </ContactCard>
                            }
                            {node.contact.type === ContactTypes.Man &&
                            <ContactCard>
                                <ContactCardHeader
                                    avatar={
                                        <FaceIcon/>
                                    }
                                    title={node.contact.position}
                                    subheader={node.contact.fio}
                                    action={buttons && buttons[0]}
                                />
                            </ContactCard>
                            }
                            {node.contact.type === ContactTypes.Phone &&
                                <PhoneBlock
                                    city={city}
                                    title={node.contact.title}
                                    number={node.contact.number}
                                    action={buttons && buttons[0]}/>
                            }
                            {node.contact.type === ContactTypes.Schedule &&
                            <ContactCard>
                                <ContactCardHeader
                                    avatar={
                                        <ScheduleIcon />
                                    }
                                    title={<ScheduleTitle schedule={node.contact}/>}
                                    subheader={node.contact.text}
                                    action={buttons && buttons[0]}
                                />
                            </ContactCard>
                            }
                        </div>
                    )}
                </div>
            </div>
        );
    }
}