import * as React from 'react';
import {Button, createStyles} from "@material-ui/core";
import {makeStyles} from '@material-ui/styles';
import commonStyles from "common/styles";
import FormControl from '@material-ui/core/FormControl';
// @ts-ignore
import {removeNodeAtPath, SortableTreeWithoutDndContext as SortableTree} from 'react-sortable-tree';
import {Field} from "react-final-form";
import {ContactNodeRenderer, NewContacts} from "courts/components";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {City} from "../models";
import InputLabel from "@material-ui/core/InputLabel";
import {Contact} from "../../courts/models";
const classNames = require('classnames');

const useStyles = makeStyles(() => createStyles({
    formControl: {
        ...commonStyles.formControl
    },
    treeFormControl: {
        paddingTop: "0.5rem"
    },
    wrapper: {
        position: "relative"
    },
    clearBtn: {
        float: "right",
        //position: "absolute",
        top: "0.5rem",
        right: "0.5rem"
    }
}));

interface Props {
    city: City;
    site: string;
    name: string;
    label?: string;
    allowCreate?: boolean;
    allowDelete?: boolean;
    externalNodeType: string;
}

interface TreeNode {
    contact: Contact;
    title: string;
    subtitle?: string;
    expanded?: boolean;
    children?: TreeNode[];
}

export const TreeField: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const {name, label, externalNodeType} = props;
    const getNodeKey = (prop: any) => prop.treeIndex;
    return (
        <Field
            name={name}
            component="input"
            type="text"
        >
            {({input, meta, ...rest}) => {
                const onAdd = (newContacts: Contact[]) => {
                    let contactsTree: TreeNode[] = input.value?[...input.value]:[];
                    for (let i = 0; i < newContacts.length; i++) {
                        let newContact = {
                            contact: newContacts[i],
                            title: "Заголовок",
                            expanded: true,
                            children: []
                        } as TreeNode;
                        if (newContacts[i].children) {
                            // @ts-ignore
                            for (let j = 0; j < newContacts[i].children.length; j++) {
                                // @ts-ignore
                                const chContact = newContacts[i].children[j];
                                // @ts-ignore
                                newContact.children.push({
                                    contact: chContact,
                                    title: "Заголовок",
                                    expanded: true
                                } as TreeNode);
                            }
                        }
                        contactsTree.push(newContact)
                    }
                    input.onChange(contactsTree);
                };

                const initial = input.value?input.value:[];

                const clearAll = () => {
                    input.onChange([]);
                };

                return <div className={classes.wrapper}>
                    {props.allowDelete && <Button
                        className={classes.clearBtn}
                        size={"small"}
                        onClick={() => {clearAll();}}
                        variant={"contained"}
                        color={"secondary"}>
                        Очистить
                    </Button>}
                    <FormControl className={classNames(classes.formControl, classes.treeFormControl)}>
                        {label && <InputLabel shrink>{label}</InputLabel>}
                        <SortableTree
                            isVirtualized={false}
                            treeData={initial}
                            dndType={externalNodeType}
                            onChange={input.onChange}
                            nodeContentRenderer={ContactNodeRenderer}
                            generateNodeProps={(prop: any) => ({
                                city: props.city,
                                buttons: [
                                    <IconButton
                                        size={"small"}
                                        color={"secondary"}
                                        aria-label="delete"
                                        onClick={() => input.onChange(removeNodeAtPath({
                                            treeData: input.value,
                                            path: prop.path,
                                            getNodeKey,
                                        }))}>
                                        <DeleteIcon/>
                                    </IconButton>,
                                ],
                            })}
                        />
                        {props.allowCreate && <NewContacts
                            onAdd={onAdd}
                            city={props.city}
                            site={props.site}/>}
                    </FormControl>
                </div>
            }}
        </Field>

    );
};

