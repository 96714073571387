import {ContactAddress} from "./ContactAddress";
import {ContactCabinet} from "./ContactCabinet";
import {ContactDepartment} from "./ContactDepartment";
import {ContactEmail} from "./ContactEmail";
import {ContactSchedule} from "./ContactSchedule";
import {ContactPhone} from "./ContactPhone";
import {ContactMan} from "./ContactMan";
import {ContactSite} from "./ContactSite";

export enum ContactTypes {
    Address = 'address',
    Cabinet = 'cabinet',
    Department = 'department',
    Email = 'email',
    Man = 'man',
    Phone = 'phone',
    Schedule = 'schedule',
    Site = 'site',
}

export type Contact = ContactAddress | ContactCabinet | ContactDepartment | ContactEmail | ContactMan | ContactPhone | ContactSchedule | ContactSite;