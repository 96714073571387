import {Uploaded} from "common/models";
import {ApiClient} from "../../api-client";

const uploadImageFromEditor = (f: File) => {
    const formData = new FormData();
    formData.append('file', f, f.name);

    return ApiClient.post('/core/images/', formData)
        .then((response: any) => {
            const image = response.data as Uploaded;
            console.dir({
                success: 1,
                file: {
                    ...image,
                    url: process.env.REACT_APP_DOMAIN + image.source,
                },
            });
            return {
                success: 1,
                file: {
                    ...image,
                    url: process.env.REACT_APP_DOMAIN + image.source,
                },
            }
        });
};

const uploadFromEditor = (f: File) => {
    const formData = new FormData();
    formData.append('file', f, f.name);

    return ApiClient.post('/core/files/', formData)
        .then((response: any) => {
            const uploaded = response.data as Uploaded;
            return {
                success: 1,
                file: {
                    ...uploaded,
                    url: process.env.REACT_APP_DOMAIN + uploaded.source,
                },
            }
        });
};


export const filesActions = {
    uploadImageFromEditor,
    uploadFromEditor,
};