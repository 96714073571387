import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import LinearProgress from '@material-ui/core/LinearProgress';
import {postsActions} from "../store/actions";
import {StateInterface} from "reducers";
import 'react-sortable-tree/style.css';
import {CustomTreeData, TreeDataState, FilteringState} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {ActionButton, ActiveIndicator, Layout} from "common/components";
import {Post} from "../models";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {Typography, Button, createStyles, Theme, Dialog, DialogTitle} from "@material-ui/core";
import {makeStyles} from '@material-ui/styles';
import {MetaDialog} from "./MetaDialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


const getChildRows = (row: any, rootRows: any) => {
    if (row) {
        if (row.children && row.children.length !== 0) {
            return row.children;
        }
        return undefined;
    }
    return rootRows
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    addPostBtn: {
        marginBottom: '0.5rem'
    }
}));

export const PostsPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(postsActions.fetchAll());
        window.scrollTo(0, 0);
    },[dispatch]);

    const deletePost = (id: number) => {
        dispatch(postsActions.deletePost(id));
        setDeletingPost(null);
    };

    const posts = useSelector((state: StateInterface) => state.posts.posts.data);
    const isLoading = useSelector((state: StateInterface) => state.posts.posts.isLoading);
    const [showDialog, setShowDialog] = useState(false);
    const [deletingPost, setDeletingPost] = React.useState<Post | null>(null);

    const [columns] = useState([
        { name: 'headline', title: 'Название', getCellValue: (row: Post) => row.headline },
        { name: '', title: '', getCellValue: (row: Post) => {
            return <div style={{textAlign: "right"}}>
                <Button
                    size={"small"}
                    color={"primary"}
                    disabled
                >
                    <ActiveIndicator active={row.status === 'publish'}/>
                </Button>
                <ActionButton
                icon={<EditIcon/>}
                label={"Редактировать"}
                url={'/posts/' + String(row.id)} />
                <Button
                    size={"small"}
                    color={"secondary"}
                    onClick={() => setDeletingPost(row)}>
                    <DeleteIcon />
                    <span style={{marginLeft: '0.5rem',}}>{"Удалить"}</span>
                </Button>
                {row.status === 'publish'?
                    <ActionButton icon={<VisibilityIcon/>} url={`${process.env.REACT_APP_DOMAIN}/${row.slug}`}/>:
                    <Button
                        size={"small"}
                        color="primary"
                        disabled
                    >
                        <VisibilityIcon/>
                    </Button>
                }
            </div>
        }},
    ]);

    const createPage = async (values: Post) => {
        try {
            values.status = 'draft';
            await dispatch(postsActions.create(values));
            await dispatch(postsActions.fetchAll());
            setShowDialog(false);
        } catch (e) {
            console.dir(e);
            return e;
        }
    };

    return (
        <Layout pageName={'Статьи'}>
            <Button
                className={classes.addPostBtn}
                variant="contained"
                color="primary"
                onClick={() => setShowDialog(true)}>
                <AddIcon className={classes.leftIcon}/> Добавить статью
            </Button>
            {isLoading ?
                <LinearProgress/>
                :
                <>
                    {posts.length === 0 ?
                        <Typography
                            variant={'h3'}
                            component={'h3'}>{'Пусто'}</Typography>:
                        <Grid
                            rows={posts}
                            columns={columns}
                        >
                            <FilteringState/>
                            <TreeDataState />
                            <CustomTreeData
                                getChildRows={getChildRows}
                            />
                            <Table/>
                            <TableHeaderRow />
                            <TableTreeColumn
                                for="headline"
                            />
                        </Grid>
                    }
                </>
            }
            {showDialog &&
                <MetaDialog
                    title={"Добавление страницы"}
                    posts={posts}
                    onClose={() => setShowDialog(false)}
                    onSubmit={createPage}
                />
            }
            {deletingPost &&
            <Dialog
                open={true}
                onClose={() => setDeletingPost(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Удалить статью?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Вы уверены, что хотите удалить статью <b>{deletingPost.headline}</b>? Восстановить удалённую статью будет невозможно.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => deletePost(deletingPost.id)} color="primary">
                        Да
                    </Button>
                    <Button onClick={() => setDeletingPost(null)} color="secondary" autoFocus>
                        Нет
                    </Button>
                </DialogActions>
            </Dialog>
            }
        </Layout>
    );
};

