import * as React from 'react';
import 'react-sortable-tree/style.css';
import {
    ContactAddress,
    ContactCabinet,
    ContactDepartment,
    ContactEmail,
    ContactMan,
    ContactPhone,
    ContactSchedule, ContactSite,
    ContactTypes
} from "../models";
import {DragSource} from "react-dnd";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import HomeIcon from '@material-ui/icons/Home';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhoneIcon from '@material-ui/icons/Phone';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import GroupIcon from '@material-ui/icons/Group';
import FaceIcon from '@material-ui/icons/Face';
import LinkIcon from '@material-ui/icons/Link';
import {ContactCard} from "./ContactCard";
import {ContactCardHeader} from "./ContactCardHeader";
import {ContactCardContent} from "./ContactCardContent";

interface Props {
    node: any;
    connectDragSource: any;
    onDelete?: any;
}


const contactRenderResolver: React.FC<Props> = (props: Props) => {
    const removeContact = () => {
        props.onDelete(props.node.contact);
    };

    if (props.node.contact.type === ContactTypes.Man) {
        const contact = props.node.contact as ContactMan;
        return props.connectDragSource(
            <div>
                <ContactCard>
                    <ContactCardHeader
                        avatar={
                            <FaceIcon/>
                        }
                        action={
                            <IconButton
                                size={"small"}
                                color={"secondary"}
                                onClick={removeContact}
                                aria-label="delete">
                                <DeleteIcon/>
                            </IconButton>
                        }
                        title={contact.position}
                        subheader={contact.fio}
                    />
                    {contact.text &&
                    <ContactCardContent>
                        {contact.text}
                    </ContactCardContent>
                    }
                </ContactCard>
            </div>
            ,
            { dropEffect: 'move' })
    }
    if (props.node.contact.type === ContactTypes.Department) {
        const contact = props.node.contact as ContactDepartment;
        return props.connectDragSource(
            <div>
                <ContactCard>
                    <ContactCardHeader
                        avatar={
                            <GroupIcon/>
                        }
                        action={
                            <IconButton
                                size={"small"}
                                color={"secondary"}
                                onClick={removeContact}
                                aria-label="delete">
                                <DeleteIcon/>
                            </IconButton>
                        }
                        title={contact.title}
                        subheader={contact.name}
                    />
                    {contact.text &&
                    <ContactCardContent>
                        {contact.text}
                    </ContactCardContent>
                    }
                </ContactCard>
            </div>
            ,
            { dropEffect: 'move' })
    }
    if (props.node.contact.type === ContactTypes.Email) {
        const contact = props.node.contact as ContactEmail;
        return props.connectDragSource(
            <div>
                <ContactCard>
                    <ContactCardHeader
                        avatar={
                            <ContactMailIcon/>
                        }
                        action={
                            <IconButton
                                size={"small"}
                                color={"secondary"}
                                onClick={removeContact}
                                aria-label="delete">
                                <DeleteIcon/>
                            </IconButton>
                        }
                        title={contact.title}
                        subheader={contact.email}
                    />
                    {contact.text &&
                    <ContactCardContent>
                        {contact.text}
                    </ContactCardContent>
                    }
                </ContactCard>
            </div>
            ,
            { dropEffect: 'move' })
    }
    if (props.node.contact.type === ContactTypes.Site) {
        const contact = props.node.contact as ContactSite;
        return props.connectDragSource(
            <div>
                <ContactCard>
                    <ContactCardHeader
                        avatar={
                            <LinkIcon/>
                        }
                        action={
                            <IconButton
                                size={"small"}
                                color={"secondary"}
                                onClick={removeContact}
                                aria-label="delete">
                                <DeleteIcon/>
                            </IconButton>
                        }
                        title={contact.title}
                        subheader={contact.site}
                    />
                    {contact.text &&
                    <ContactCardContent>
                        {contact.text}
                    </ContactCardContent>
                    }
                </ContactCard>
            </div>,
            { dropEffect: 'move' })
    }
    if (props.node.contact.type === ContactTypes.Address) {
        const contact = props.node.contact as ContactAddress;
        return props.connectDragSource(
            <div>
                <ContactCard>
                    <ContactCardHeader
                        avatar={
                            <HomeIcon/>
                        }
                        action={
                            <IconButton
                                size={"small"}
                                color={"secondary"}
                                onClick={removeContact}
                                aria-label="delete">
                                <DeleteIcon/>
                            </IconButton>
                        }
                        title={contact.title}
                        subheader={contact.address}
                    />
                    {contact.text &&
                    <ContactCardContent>
                        {contact.text}
                    </ContactCardContent>
                    }
                </ContactCard>
            </div>
            ,
            { dropEffect: 'move' })
    }
    if (props.node.contact.type === ContactTypes.Cabinet) {
        const contact = props.node.contact as ContactCabinet;
        return props.connectDragSource(
            <div>
                <ContactCard>
                    <ContactCardHeader
                        avatar={
                            <VpnKeyIcon/>
                        }
                        action={
                            <IconButton
                                size={"small"}
                                color={"secondary"}
                                onClick={removeContact}
                                aria-label="delete">
                                <DeleteIcon/>
                            </IconButton>
                        }
                        title={contact.title}
                        subheader={contact.number}
                    />
                    {contact.text &&
                    <ContactCardContent>
                        {contact.text}
                    </ContactCardContent>
                    }
                </ContactCard>
            </div>
            ,
            { dropEffect: 'move' })
    }
    if (props.node.contact.type === ContactTypes.Phone) {
        const contact = props.node.contact as ContactPhone;
        return props.connectDragSource(
            <div>
                <ContactCard>
                    <ContactCardHeader
                        avatar={
                            <PhoneIcon/>
                        }
                        action={
                            <IconButton
                                size={"small"}
                                color={"secondary"}
                                onClick={removeContact}
                                aria-label="delete">
                                <DeleteIcon/>
                            </IconButton>
                        }
                        title={contact.title}
                        subheader={contact.number}
                    />
                    {contact.text &&
                    <ContactCardContent>
                        {contact.text}
                    </ContactCardContent>
                    }
                </ContactCard>
            </div>
            ,
            { dropEffect: 'move' })
    }
    if (props.node.contact.type === ContactTypes.Schedule) {
        const contact = props.node.contact as ContactSchedule;
        /*
        {contact.text &&
        <ContactCardContent>
            {contact.text}
        </ContactCardContent>
        }
         */
        return props.connectDragSource(
            <div>
                <ContactCard>
                    <ContactCardHeader
                        avatar={
                            <PhoneIcon/>
                        }
                        action={
                            <IconButton
                                size={"small"}
                                color={"secondary"}
                                onClick={removeContact}
                                aria-label="delete">
                                <DeleteIcon/>
                            </IconButton>
                        }
                        title={contact.title}
                        subheader={contact.schedule}
                    />
                </ContactCard>
            </div>
            ,
            { dropEffect: 'move' })
    }
    return props.connectDragSource(<div>{props.node.contact.type}</div>,
        { dropEffect: 'move' });
};

export const externalNodeType = 'yourNodeType';
const externalNodeSpec = {
    // This needs to return an object with a property `node` in it.
    // Object rest spread is recommended to avoid side effects of
    // referencing the same object in different trees.
    beginDrag: (componentProps: any) => ({ node: { ...componentProps.node } }),
};

const externalNodeCollect = (connect: any /* , monitor */) => ({
    connectDragSource: connect.dragSource(),
    // Add props via react-dnd APIs to enable more visual
    // customization of your component
    // isDragging: monitor.isDragging(),
    // didDrop: monitor.didDrop(),
});

export const ContactRenderResolver = DragSource(
    externalNodeType,
    externalNodeSpec,
    externalNodeCollect
)(contactRenderResolver);