import {types} from "courts/store/actions/types";
import {CourtsState} from "../states";

const initialState = (): CourtsState => ({
    data: [],
    isLoading: false,
    filters: null,
});

export const CourtsReducer = (state = initialState(), action: any) => {
    switch (action.type) {
        case types.COURTS_LOADING_PROCESS:
            return Object.assign({}, state, {
                isLoading: true,
                filters: action.payload.filters,
            });
        case types.COURTS_LOADING_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                data: action.payload.data,
            });
    }
    return state;
};