import {combineReducers} from 'redux';
import {CourtsReducer} from "./CourtsReducer";
import {CourtsAppState} from "../states";
import {CourtReducer} from "./CourtReducer";
import {SectorReducer} from "./SectorReducer";

export const CourtsAppReducer = combineReducers<CourtsAppState>({
    court: CourtReducer,
    sector: SectorReducer,
    courts: CourtsReducer,
});