import {types} from './types';
import {Court, CourtsFilters} from "courts/models";
import {Error} from "common/models";
import {helpers} from "common/helpers";
import * as HttpStatus from 'http-status-codes';
import {FORM_ERROR} from "final-form";
import cogoToast from 'cogo-toast';
import {ApiClient} from "../../../common/api-client";

const fetchAll = (filters: CourtsFilters) => (dispatch: any) => {
    let searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
        if (value) {
            searchParams.append(key, value as string)
        }
    });

    dispatch(courtsLoadingProcess(filters));

    return ApiClient.get('/courts/courts/', {params: searchParams})
        .then((response: any) => {
            dispatch(courtsLoadingSuccess(response.data.data as Court[]));
        })
        .catch(function (error: any) {
            console.dir(error);
        });
};

const courtsLoadingProcess = (filters: CourtsFilters) => ({
    type: types.COURTS_LOADING_PROCESS,
    payload: {
        filters: filters,
    }
});

const courtsLoadingSuccess = (courts: Court[]) => ({
    type: types.COURTS_LOADING_SUCCESS,
    payload: {
        data: courts,
    }
});

const update = (court: Court) => (dispatch: any) => {
    let data = Object.assign({}, court) as any;

    return ApiClient.put(`/courts/courts/${court.id}/`, data)
        .then(function (response: any) {
            cogoToast.success('Изменения сохранены', {position: 'bottom-left'});
            return response.data
        })
        .catch(function (error: any) {
            cogoToast.error('При сохранении возникли ошибки', {position: 'bottom-left'});
            let errors: Error = {};
            if (error.response.status === HttpStatus.BAD_REQUEST) {
                errors = helpers.processErrors(error.response.data);
            } else {
                errors[FORM_ERROR] = "Что-то пошло не так... Код: " + error.response.status;
            }

            return Promise.reject(errors);
        });
};

const savePreview = (court: Court) => (dispatch: any) => {
    let data = Object.assign({}, court) as any;

    return ApiClient.post(`/courts/${court.id}/save_preview`, data)
        .then(function (response: any) {
            return response.data
        })
        .catch(function (error: any) {
            console.dir(error);
            let errors: Error = {};
            if (error.response.status === HttpStatus.BAD_REQUEST) {
                for (let [key, value] of Object.entries(error.response.data)) {
                    errors[key === 'non_field_errors' ? FORM_ERROR : key] = (value as string[])[0];
                }
            } else {
                errors[FORM_ERROR] = "Что-то пошло не так... Код: " + error.response.status;
            }

            return Promise.reject(errors);
        });
};


const fetch = (id: number) => (dispatch: any) => {
    dispatch(courtLoadingProcess());

    return ApiClient.get(`/courts/courts/${id}/`)
        .then((response: any) => {
            let court = response.data.data as Court;
            dispatch(courtLoadingSuccess(court));
        })
        .catch(function (error: any) {
            console.dir(error);
            return Promise.reject(error);
        });
};

const courtLoadingProcess = () => ({
    type: types.COURT_LOADING_PROCESS
});

const courtLoadingSuccess = (court: Court) => ({
    type: types.COURT_LOADING_SUCCESS,
    payload: {
        data: court,
    }
});

export const courtsActions = {
    fetch,
    fetchAll,
    update,
    savePreview,
};